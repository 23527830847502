export const ROUTE_CONSTANTS = {
  BASE: '/',
  DASHBOARD: 'dashboard',
  JOB_ORDERS: 'jobs',
  JOB_ORDER_CREATE: 'jobs/create',
  LONG_TERM_JOBS: 'jobs/longTermJobsList',
  CORPORATIONS: 'corporations/corporationList',
  CORPORATION_CREATE: 'corporations/createCorporation',
  USERS: 'users/usersList',
  USERS_V2: 'users',
  POSITION_TEMPLATES: 'jobs/positionTemplateList',
  TIMEKEEPING: 'timekeeping',
  REGISTER: 'register',
  REPORTING: 'reporting',
  SETTINGS: 'settings',
  FORGOT_MY_PASSWORD: 'forgotMyPassword',
  PASSWORD_RESET_QUERY_INFORMATION: 'passwordReset/:queryinformation',
  SKILLS_AND_CERTS: 'skillsAndCerts',
  ACCOUNT_VERIFICATION_QUERY_INFORMATION: 'accountverification/:queryinformation',
};

export const searchParamOptions = {
  // ALL THESE ARE TEXT OF THE VALUE - not truly number/boolean
  PLACEMENT: 'placement', // ID
  WEEK_START: 'weekStart', // number (epoch)
  ACTIVE_TAB: 'activeTab', // number (index of array)
  JOB_TYPE: 'jobType', // 'long' || 'day' || 'both
  JOB_ORDER: 'jobOrder', // ID
  CANDIDATE: 'candidate', // ID
  SELECTED_DATE: 'selectedDate', // number (epoch)
  LOCATION: 'location', // ID
  SHIFT_GROUP: 'shiftGroup', // ID
  POSITION: 'positionTemplate', // ID
  INVITED: 'isInvite', // 'true || 'false'
};
