import React from 'react';
import PropTypes from 'prop-types';

import {
  healthcareJobTypes,
  hospitalityJobTypes,
  manufacturingJobTypes,
} from '@careerstart/wae-common/src/main/constants/jobCategories';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Modal } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import theme from '../../../../../theme';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';
import { capitalizeFirstLetter } from '../../../UsersHelpers';

/* eslint-disable react/no-array-index-key */
const formatJobType = (jobType) =>
  jobType
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
    .trim();

const getJobDescription = (jobType) => {
  switch (jobType) {
    case 'CNA':
      return LanguageConverter('userDetail.experience.healthcare.cna');
    case 'LPN':
      return LanguageConverter('userDetail.experience.healthcare.lpn');
    case 'RN':
      return LanguageConverter('userDetail.experience.healthcare.rn');
    default:
      return '';
  }
};

const styles = theme?.components?.userDetail;
const WorkExperienceModal = ({ open, onClose, workExperience, industry }) => {
  const hasManufacturing = industry?.includes('manufacturing');
  const hasHospitality = industry?.includes('hospitality');
  const hasHealthcare = industry?.includes('healthcare');

  const renderJobTypeSection = (title, jobTypes, isHealthcare = false) => (
    <>
      <Typography sx={{ ...styles.textStyle, mt: 3, mb: 2 }}>{title}</Typography>
      {jobTypes.map((jobType) => (
        <Box key={jobType} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={styles.subTitleStyle}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {isHealthcare ? jobType : formatJobType(jobType)}
              {isHealthcare && (
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: PRIMARY_FONT[500],
                    fontSize: '16px',
                  }}
                >
                  {getJobDescription(jobType)}
                </Typography>
              )}
            </Box>
            <Select
              value={workExperience?.[jobType] || 0}
              readOnly
              disabled
              size="small"
              sx={{
                width: 100,
                height: 35,
                '.MuiSelect-select': {
                  py: 1,
                },
              }}
            >
              {[...Array(6)].map((_, i) => (
                <MenuItem key={i} value={i}>
                  {i}{' '}
                  {LanguageConverter(
                    i === 1 ? 'userDetail.experience.year' : 'userDetail.experience.years'
                  )}
                </MenuItem>
              ))}
            </Select>
          </Typography>
        </Box>
      ))}
    </>
  );

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="work-experience-modal">
      <Box sx={styles.modalStyle}>
        <Typography sx={styles.headerStyle}>
          {LanguageConverter('userDetail.experience.header')}
        </Typography>
        <Typography sx={styles.textStyle}>
          {LanguageConverter('userDetail.experience.info')}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography style={styles.textStyle}>
            {LanguageConverter('userDetail.experience.industrySelected')}
          </Typography>
          {industry?.map((industryName, index) => (
            <Typography key={index} sx={{ ...styles.subHeaderStyle, marginLeft: 1 }}>
              {capitalizeFirstLetter(industryName)}
            </Typography>
          ))}
        </Box>
        {hasManufacturing && (
          <>
            {renderJobTypeSection(
              LanguageConverter('userDetail.experience.manufactoringExperience'),
              manufacturingJobTypes
            )}
            {(hasHospitality || hasHealthcare) && (
              <Box sx={{ my: 2, borderBottom: '1px solid #eee' }} />
            )}
          </>
        )}

        {hasHospitality && (
          <>
            {renderJobTypeSection(
              LanguageConverter('userDetail.experience.hospitalityExperience'),
              hospitalityJobTypes
            )}
            {hasHealthcare && <Box sx={{ my: 2, borderBottom: '1px solid #eee' }} />}
          </>
        )}

        {hasHealthcare &&
          renderJobTypeSection(
            LanguageConverter('userDetail.experience.healthcareExperience'),
            healthcareJobTypes,
            true
          )}
      </Box>
    </Modal>
  );
};

WorkExperienceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  workExperience: PropTypes.shape({}),
  industry: PropTypes.arrayOf(PropTypes.string),
};

export default WorkExperienceModal;
