import * as React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';

import theme from '../../../theme';
import { BLACK } from '../../../theme/colorConstants';

import Attendance from './Attendnace';
import ShiftContent from './ShiftContent';

const styles = theme?.components?.userCard;

const Details = ({ userInfo }) => {
  switch (userInfo.status) {
    case 'unverified':
      return (
        <Box
          sx={{
            border: 1,
            borderColor: BLACK[40],
            borderRadius: 2,
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 5,
          }}
        >
          <Typography sx={styles.contentStyle}>
            {LanguageConverter('userCard.subTitle.waitingForVerification')}
          </Typography>
        </Box>
      );

    case 'active':
      return (
        <Box>
          <Typography sx={styles.detailHeader}>
            {LanguageConverter('userCard.subTitle.details')}
          </Typography>
          <Typography sx={styles.detailSubTitleStyle} data-testid="reliability-score">
            {LanguageConverter('userCard.subTitle.reliabilityScore')} {userInfo.reliabilityScore}
          </Typography>
          <Attendance attendance={userInfo?.attendance} />
          <Box display="flex" alignItems="center" gap={1}>
            <Typography sx={styles.detailSubTitleStyle}>
              {LanguageConverter('userCard.subTitle.shiftPreference')}
            </Typography>
            <ShiftContent preferredShiftTimes={userInfo?.preferredShiftTimes} />
          </Box>
          <Box />
        </Box>
      );

    default:
      return null;
  }
};

Details.propTypes = {
  userInfo: PropTypes.shape({
    status: PropTypes.string.isRequired,
    reliabilityScore: PropTypes.string,
    attendance: PropTypes.number.isRequired,
    preferredShiftTimes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default Details;
