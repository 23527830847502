import * as React from 'react';
import PropTypes from 'prop-types';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Grid, Skeleton, Typography } from '@mui/material';

import { PRIMARY_FONT } from '../../../theme/fontConstants';

const COLORS = {
  activeWorkers: '#D7BDFF',
  pendingOnboarding: '#53A5D2',
  waitingVerification: '#E79161',
};

const CustomLabel = ({ viewBox, total = 0 }) => {
  const { cx, cy } = viewBox;
  return (
    <>
      <text x={cx} y={cy - 15} textAnchor="middle">
        <tspan
          style={{
            fontSize: '14px',
            fontFamily: PRIMARY_FONT[300],
            textDecoration: 'underline',
          }}
        >
          {LanguageConverter('dashboard.widget.worker.total')}
        </tspan>
      </text>
      <text x={cx} y={cy} textAnchor="middle">
        <tspan
          style={{
            fontSize: '14px',
            fontFamily: PRIMARY_FONT[300],
            textDecoration: 'underline',
          }}
        >
          {LanguageConverter('dashboard.widget.worker.workers')}
        </tspan>
      </text>
      <text x={cx} y={cy + 20} textAnchor="middle">
        <tspan
          style={{
            fontSize: '16px',
            fontFamily: PRIMARY_FONT[400],
          }}
        >
          {total}
        </tspan>
      </text>
    </>
  );
};

CustomLabel.propTypes = {
  viewBox: PropTypes.shape(),
  total: PropTypes.number,
};

const WorkerStatusWidget = ({
  isLoading,
  activeWorkers,
  pendingOnboarding,
  waitingVerification,
  atRisk,
  total,
}) => {
  const data = [
    { name: 'activeWorkers', value: activeWorkers, color: COLORS.activeWorkers },
    { name: 'pendingOnboarding', value: pendingOnboarding, color: COLORS.pendingOnboarding },
    { name: 'waitingVerification', value: waitingVerification, color: COLORS.waitingVerification },
  ];
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        minWidth: '500px',
        height: '233px',
        borderRadius: '8px',
        boxShadow: 2,
      }}
    >
      {isLoading ? (
        <Skeleton height="inherit" variant="rectangular" sx={{ borderRadius: '8px' }} />
      ) : (
        <Grid
          container
          sx={{ padding: '12px', direction: 'row', height: '100%', maxWidth: '600px' }}
        >
          <Grid container item xs={5}>
            <Typography sx={{ fontSize: '20px', fontFamily: PRIMARY_FONT[700] }}>
              {LanguageConverter('dashboard.widget.worker.workersTitle')}
            </Typography>
            <Grid>
              <Grid container sx={{ direction: 'row', alignItems: 'center', gap: '10px' }}>
                <Box
                  sx={{
                    width: '18px',
                    height: '16px',
                    backgroundColor: COLORS.activeWorkers,
                    borderRadius: '5px',
                    margin: '2px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: PRIMARY_FONT[300],
                    textDecoration: 'underline',
                  }}
                >
                  {LanguageConverter('dashboard.widget.worker.activeWorkers')}
                </Typography>
              </Grid>
              <Grid container sx={{ direction: 'row', alignItems: 'center', gap: '10px' }}>
                <Box
                  sx={{
                    width: '18px',
                    height: '16px',
                    backgroundColor: COLORS.pendingOnboarding,
                    borderRadius: '5px',
                    margin: '2px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: PRIMARY_FONT[300],
                    textDecoration: 'underline',
                  }}
                >
                  {LanguageConverter('dashboard.widget.worker.pendingOnboarding')}
                </Typography>
              </Grid>
              <Grid container sx={{ direction: 'row', alignItems: 'center', gap: '10px' }}>
                <Box
                  sx={{
                    width: '18px',
                    height: '16px',
                    backgroundColor: COLORS.waitingVerification,
                    borderRadius: '5px',
                    margin: '2px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: PRIMARY_FONT[300],
                    textDecoration: 'underline',
                  }}
                >
                  {LanguageConverter('dashboard.widget.worker.waitingVerification')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ direction: 'row', alignItems: 'center', gap: '10px' }}>
              <WarningAmberRoundedIcon sx={{ color: '#F0A003' }} />
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: PRIMARY_FONT[300],
                  textDecoration: 'underline',
                }}
              >
                {`${LanguageConverter('dashboard.widget.worker.workersAtRisk')} (${atRisk})`}
              </Typography>
              <Typography sx={{ fontSize: '12px', fontFamily: PRIMARY_FONT[300] }}>
                {LanguageConverter('dashboard.widget.worker.last90Days')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={30} height={30}>
                <Pie
                  data={data}
                  innerRadius={40}
                  outerRadius={70}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
                    const RADIAN = Math.PI / 180;
                    const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    return (
                      <text
                        x={x}
                        y={y}
                        textAnchor={x > cx ? 'start' : 'end'}
                        dominantBaseline="hanging"
                        fontFamily={PRIMARY_FONT[500]}
                        fontSize="16px"
                      >
                        {data[index].value}
                      </text>
                    );
                  }}
                  stroke="none"
                  labelLine={false}
                >
                  {data.map((entry) => (
                    <Cell key={`cell-${entry?.color}`} fill={entry?.color} />
                  ))}
                  <Label
                    value="Total Workers"
                    content={CustomLabel}
                    total={total}
                    position="center"
                  />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

WorkerStatusWidget.propTypes = {
  isLoading: PropTypes.bool,
  activeWorkers: PropTypes.number.isRequired,
  pendingOnboarding: PropTypes.number.isRequired,
  waitingVerification: PropTypes.number.isRequired,
  atRisk: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default WorkerStatusWidget;
