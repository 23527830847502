import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';

import BackButtonIcon from '../../../../assets/icons/BackButton.svg';
import FrontButtonIcon from '../../../../assets/icons/ForwardButton.svg';
import MuiIconButton from '../../../../components/IconButton/IconButton';
import { BLACK } from '../../../../theme/colorConstants';
import { epochToDayOfWeek, getWeekDate } from '../../../../utils/timeConverter';

const TimekeepingDayFilter = ({ startEpoch, handleBackButtonClick, handleForwardButtonClick }) => {
  const thisWeekSx = {
    color: `${BLACK[70]}`,
    fontFamily: 'Barlow-700',
    fontSize: '18px',
  };

  const dateRangeSx = {
    color: `${BLACK[70]}`,
    fontFamily: 'Barlow-500',
    fontSize: '18px',
    marginLeft: '8px',
  };

  const isForwardDisabled = useMemo(
    () => new Date(getWeekDate(startEpoch)) >= new Date(),
    [startEpoch]
  );

  const dateRangeText = getWeekDate(startEpoch);

  return (
    <Grid container item justifyContent="space-between" direction="row">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <MuiIconButton
          onClick={handleBackButtonClick}
          iconElement={
            <Box
              component="img"
              sx={{
                color: 'red',
                height: '22px',
                width: '22px',
              }}
              alt="Back"
              src={BackButtonIcon}
            />
          }
        />
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
          <Box sx={thisWeekSx}> {epochToDayOfWeek(startEpoch)}: </Box>
          <Box sx={dateRangeSx}>{dateRangeText}</Box>
        </Box>

        <MuiIconButton
          disabled={isForwardDisabled}
          onClick={handleForwardButtonClick}
          iconElement={
            <Box
              component="img"
              sx={{
                height: '22px',
                width: '22px',
              }}
              alt="Back"
              src={FrontButtonIcon}
            />
          }
        />
      </Box>
    </Grid>
  );
};
export default TimekeepingDayFilter;

TimekeepingDayFilter.propTypes = {
  startEpoch: PropTypes.number,
  handleBackButtonClick: PropTypes.func,
  handleForwardButtonClick: PropTypes.func,
};
