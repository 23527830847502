import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';

import CorporationIcon from '../../../assets/icons/CorporationIcon.svg';
import { SEARCHBAR_BACKGROUND } from '../../../components/FreeTextSearchFilter';
import MultipleSelectDropdownChip from '../../../components/MultipleSelectDropdownChip/MultipleSelectDropdownChip';
import { SearchableSelectDropdownFilter } from '../../../components/SearchableSelectDropdownFilter/SearchableSelectDropdownFilter';
import {
  selectIsJobOrdersLoading,
  selectJobOrders,
} from '../../../store/selectors/dashboardSelector';
import { BLACK, PRIMARY_COLOR } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import {
  epochToDateInReadableFormat,
  epochToTimeInReadableFormat,
  getHoursDifferenceFromNow,
  getTimeZone,
} from '../../../utils';
import { FILTERS, SEARCH_PARAM_KEYS, SHIFT_OPTION_KEYS } from '../../jobOrders/filterConstants';
import {
  FIVE_AM_IN_MIN,
  NINE_THIRTY_PM_IN_MIN,
  ONE_THIRTY_PM_IN_MIN,
} from '../../jobOrders/jobOrderConstants';
import {
  buildFilterPayloadFromUrl,
  getInitialSelectedShiftOptions,
  getNewShiftFilters,
} from '../../jobOrders/jobOrderHelpers';
import { getJobOrdersForDashboard } from '../dashboardReducer';

const ListTitle = ({ text }) => (
  <Typography
    sx={{
      fontSize: '12px',
      color: BLACK[70],
      textTransform: 'uppercase',
      fontFamily: PRIMARY_FONT[300],
    }}
  >
    {text}
  </Typography>
);

ListTitle.propTypes = {
  text: PropTypes.string,
};

const corporationsAPICallback = {
  httpMethod: 'POST',
  route: 'corporations/read',
  generateBody: (searchTerm) => ({
    filters: [{ operation: 'icontains', field: 'name', value: searchTerm }],
  }),
};

const MIN_NUM_HOURS = 0;
const MAX_NUM_HOURS = 24;

const shiftOptionsPayload = {
  [SHIFT_OPTION_KEYS.FIVE_AM_TO_ONE_THIRTY_PM]: {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: FIVE_AM_IN_MIN, end: ONE_THIRTY_PM_IN_MIN, zone: getTimeZone() },
  },
  [SHIFT_OPTION_KEYS.ONE_THIRTY_PM_TO_NINE_THIRTY_PM]: {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: ONE_THIRTY_PM_IN_MIN, end: NINE_THIRTY_PM_IN_MIN, zone: getTimeZone() },
  },
  [SHIFT_OPTION_KEYS.NINE_THIRTY_PM_TO_FIVE_AM]: {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: NINE_THIRTY_PM_IN_MIN, end: FIVE_AM_IN_MIN, zone: getTimeZone() },
  },
};

const limit = 5;

const INITIAL_FILTERS = {
  filters: [
    ...FILTERS.UPCOMING_JOBS,
    { field: 'full', operation: 'equals', value: false },
    { field: 'cancelled', operation: 'equals', value: false },
  ],
  page: 0,
  limit,
  sortBy: [{ field: 'start', descending: false }],
};

const shiftOptions = Object.keys(shiftOptionsPayload);

const AgencyJobsList = () => {
  const previousFiltersRef = useRef();
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsJobOrdersLoading);
  const jobOrders = useSelector(selectJobOrders);

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterAndSortData, setFilterAndSortData] = useState(() => {
    const parsedUrlFilters = buildFilterPayloadFromUrl(searchParams, []);
    return { ...INITIAL_FILTERS, filters: [...INITIAL_FILTERS.filters, ...parsedUrlFilters] };
  });
  const intl = useIntl();

  const handleCorporationChange = useCallback(
    (corporationId) => {
      if (corporationId !== searchParams.get(SEARCH_PARAM_KEYS.CORPORATION)) {
        const newParams = { ...Object.fromEntries(searchParams) };
        if (corporationId) {
          newParams[SEARCH_PARAM_KEYS.CORPORATION] = corporationId;
        } else {
          delete newParams[SEARCH_PARAM_KEYS.CORPORATION];
        }
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const handleShiftOptionChange = useCallback(
    (selectedShifts) => {
      const newParams = { ...Object.fromEntries(searchParams) };
      const selectedShiftFilters = selectedShifts.map((shift) => shiftOptionsPayload[shift]);
      const parsedShiftTime = getNewShiftFilters(selectedShiftFilters);
      const shiftStart = parsedShiftTime?.[0]?.value?.start;
      const shiftEnd = parsedShiftTime?.[0]?.value?.end;

      if (shiftStart !== undefined && shiftEnd !== undefined) {
        newParams[SEARCH_PARAM_KEYS.SHIFT_START] = shiftStart;
        newParams[SEARCH_PARAM_KEYS.SHIFT_END] = shiftEnd;
      } else {
        delete newParams[SEARCH_PARAM_KEYS.SHIFT_START];
        delete newParams[SEARCH_PARAM_KEYS.SHIFT_END];
      }

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (isLoading) return;
    const payload = {
      filters: filterAndSortData.filters,
      sortBy: [{ descending: false, field: 'start' }],
      limit: filterAndSortData.limit,
      page: filterAndSortData.page,
    };

    if (!isEqual(previousFiltersRef.current, payload)) {
      previousFiltersRef.current = payload;
      dispatch(getJobOrdersForDashboard(payload));
    }
  }, [dispatch, filterAndSortData, isLoading, searchParams]);

  useEffect(() => {
    setFilterAndSortData((prev) => {
      const filters = buildFilterPayloadFromUrl(searchParams, prev.filters);
      if (isEqual(prev.filters, filters)) {
        return prev;
      }

      if (filters.length > 0) {
        return { ...prev, page: 0, filters };
      }
      return { ...INITIAL_FILTERS, page: 0 };
    });
  }, [searchParams]);

  const initialSelectedShifts = getInitialSelectedShiftOptions(
    searchParams.get(SEARCH_PARAM_KEYS.SHIFT_START),
    searchParams.get(SEARCH_PARAM_KEYS.SHIFT_END)
  );

  const currentPagesStart = limit * filterAndSortData.page + 1;
  const currentPagesEnd =
    currentPagesStart + 4 < jobOrders?.count ? currentPagesStart + 4 : jobOrders?.count;

  return (
    <Box sx={{ width: '100%', borderRadius: '8px', gap: '8px' }}>
      <>
        <Grid container justifyContent="space-between" direction="row" flexWrap="nowrap">
          <Typography sx={{ fontSize: '20px', fontFamily: PRIMARY_FONT[700] }}>
            {`${LanguageConverter('dashboard.agent.jobsList.title')}`}
          </Typography>
          <Grid item>
            <Grid container sx={{ gap: '8px' }}>
              <Typography sx={{ fontSize: '20px', fontFamily: PRIMARY_FONT[700] }}>
                {LanguageConverter('dashboard.agent.jobsList.filters')}
              </Typography>
              <SearchableSelectDropdownFilter
                disabled={isLoading}
                placeholder="Corporation"
                onValueChange={handleCorporationChange}
                optionsAPICallback={corporationsAPICallback}
                getOptionLabel={(option) => option.name}
                background={SEARCHBAR_BACKGROUND.DEFAULT}
                initialValue={searchParams.get(SEARCH_PARAM_KEYS.CORPORATION)}
              />
              <MultipleSelectDropdownChip
                disabled={isLoading}
                options={shiftOptions}
                placeholder="Shifts"
                startAdornmentIcon={
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                    }}
                    alt="Status"
                    src={CorporationIcon}
                  />
                }
                allOptionsLabel="All Shifts"
                onValueChange={handleShiftOptionChange}
                defaultSelectedOptions={initialSelectedShifts}
              />
            </Grid>
          </Grid>
        </Grid>

        {isLoading && (
          <Grid container justifyContent="center">
            <CircularProgress sx={{ justifySelf: 'center' }} />
          </Grid>
        )}
        {jobOrders && !isLoading && (
          <>
            <Grid container gap="12px" sx={{ paddingBlock: '18px' }}>
              {jobOrders?.documents?.map((jobOrder) => (
                <Grid
                  container
                  sx={{
                    border: `1px solid #CCCCD6`,
                    borderRadius: '5px',
                  }}
                  key={jobOrder?._id}
                >
                  <Grid
                    container
                    alignItems="center"
                    sx={{ height: '43px', backgroundColor: '#F2F2F5' }}
                  >
                    <Typography
                      sx={{
                        fontSize: '15px',
                        fontFamily: PRIMARY_FONT[400],
                        paddingLeft: '12px',
                      }}
                    >
                      {epochToDateInReadableFormat(jobOrder.start)}
                    </Typography>
                    {getHoursDifferenceFromNow(jobOrder.start) >= MIN_NUM_HOURS &&
                      getHoursDifferenceFromNow(jobOrder.start) <= MAX_NUM_HOURS && (
                        <Typography
                          sx={{
                            fontSize: '15px',
                            fontFamily: PRIMARY_FONT[400],
                            color: '#B23E3E',
                            paddingLeft: '12px',
                          }}
                        >
                          {intl.formatMessage(
                            { id: 'dashboard.agent.jobsList.jobStartsInHours' },
                            {
                              hours: getHoursDifferenceFromNow(jobOrder.start),
                            }
                          )}
                        </Typography>
                      )}
                  </Grid>
                  <Grid container direction="row" flexWrap="nowrap" sx={{ padding: '12px' }}>
                    <Grid container alignItems="center" direction="row" flexWrap="nowrap">
                      {getHoursDifferenceFromNow(jobOrder.start) >= MIN_NUM_HOURS &&
                        getHoursDifferenceFromNow(jobOrder.start) <= MAX_NUM_HOURS && (
                          <ErrorOutlineIcon sx={{ color: '#B23E3E', marginRight: '8px' }} />
                        )}
                      <Grid container direction="column">
                        <ListTitle text={LanguageConverter('dashboard.agent.jobsList.company')} />
                        <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
                          {jobOrder?.corporation?.name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container direction="column">
                      <ListTitle text={LanguageConverter('dashboard.agent.jobsList.position')} />
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontFamily: PRIMARY_FONT[300],
                          color: PRIMARY_COLOR[70],
                          textDecoration: 'underline',
                        }}
                      >
                        {jobOrder.name}
                      </Typography>
                    </Grid>

                    <Grid container direction="column">
                      <ListTitle text={LanguageConverter('dashboard.agent.jobsList.times')} />
                      <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
                        {`${epochToTimeInReadableFormat(
                          jobOrder.start
                        )}-${epochToTimeInReadableFormat(jobOrder.end)}`}
                      </Typography>
                    </Grid>

                    <Grid container direction="column">
                      <ListTitle
                        text={LanguageConverter('dashboard.agent.jobsList.totalOpenings')}
                      />
                      <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
                        {jobOrder.numOpenings + jobOrder.numOverstaff}
                      </Typography>
                    </Grid>

                    <Grid container direction="column">
                      <ListTitle
                        text={LanguageConverter('dashboard.agent.jobsList.availableOpenings')}
                      />
                      <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[700] }}>
                        {jobOrder.numOpenings + jobOrder.numOverstaff - jobOrder.placementsCount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center" width="100%" gap={1}>
              <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
                {`${currentPagesStart} - ${currentPagesEnd} of ${jobOrders?.count}`}
              </Typography>
              <IconButton
                disabled={filterAndSortData.page === 0}
                onClick={() =>
                  setFilterAndSortData((prevState) => ({
                    ...prevState,
                    page: prevState.page - 1,
                  }))
                }
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton
                disabled={Math.ceil((jobOrders?.count || 0) / limit) === filterAndSortData.page + 1}
                onClick={() =>
                  setFilterAndSortData((prevState) => ({
                    ...prevState,
                    page: prevState.page + 1,
                  }))
                }
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Grid>
          </>
        )}
        {!jobOrders && !isLoading && (
          <Typography
            justifySelf="center"
            sx={{ padding: '36px', fontSize: '20px', fontFamily: PRIMARY_FONT[700] }}
          >
            {LanguageConverter('dashboard.agent.jobsList.noAvailableJobs')}
          </Typography>
        )}
      </>
    </Box>
  );
};

export default AgencyJobsList;
