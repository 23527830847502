export const SHIFT_TYPES = {
  MORNING: 'morning',
  EVENING: 'evening',
  NIGHT: 'night',
  CUSTOM: 'custom',
};

export const SHIFT_GROUP = {
  MORNING_START: 6,
  MORNING_END: 14,
  EVENING_START: 14,
  EVENING_END: 22,
  NIGHT_START: 22,
  NIGHT_END: 6,
};

export const PRESET_SHIFTS = {
  MORNING: {
    start: SHIFT_GROUP.MORNING_START,
    end: SHIFT_GROUP.MORNING_END,
    type: SHIFT_TYPES.MORNING,
  },
  EVENING: {
    start: SHIFT_GROUP.EVENING_START,
    end: SHIFT_GROUP.EVENING_END,
    type: SHIFT_TYPES.EVENING,
  },
  NIGHT: {
    start: SHIFT_GROUP.NIGHT_START,
    end: SHIFT_GROUP.NIGHT_END,
    type: SHIFT_TYPES.NIGHT,
  },
};

export const SHIFT_HOUR_OPTIONS = {
  0: '12:00 AM',
  1: '1:00 AM',
  2: '2:00 AM',
  3: '3:00 AM',
  4: '4:00 AM',
  5: '5:00 AM',
  6: '6:00 AM',
  7: '7:00 AM',
  8: '8:00 AM',
  9: '9:00 AM',
  10: '10:00 AM',
  11: '11:00 AM',
  12: '12:00 PM',
  13: '1:00 PM',
  14: '2:00 PM',
  15: '3:00 PM',
  16: '4:00 PM',
  17: '5:00 PM',
  18: '6:00 PM',
  19: '7:00 PM',
  20: '8:00 PM',
  21: '9:00 PM',
  22: '10:00 PM',
  23: '11:00 PM',
};
