import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const DNAUserIcon = () => (
  <SvgIcon>
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 3L1.28 1.75L10.51 11L17.25 17.72L15.98 19L12.98 16H1.25V13C1.25 11.14 3.86 9.92 6.34 9.36L0 3ZM17.25 13V15.18L15.35 13.28V13C15.35 12.74 14.85 12.35 14.05 12L11.25 9.18C13.96 9.63 17.25 10.91 17.25 13ZM3.15 13V14.1H11.08L7.97 11C5.44 11.3 3.15 12.45 3.15 13ZM9.25 0C10.3109 0 11.3283 0.421427 12.0784 1.17157C12.8286 1.92172 13.25 2.93913 13.25 4C13.25 5.95 11.85 7.58 10 7.93L5.32 3.25C5.67 1.4 7.3 0 9.25 0ZM9.25 2C8.71957 2 8.21086 2.21071 7.83579 2.58579C7.46071 2.96086 7.25 3.46957 7.25 4C7.25 4.53043 7.46071 5.03914 7.83579 5.41421C8.21086 5.78929 8.71957 6 9.25 6C9.78043 6 10.2891 5.78929 10.6642 5.41421C11.0393 5.03914 11.25 4.53043 11.25 4C11.25 3.46957 11.0393 2.96086 10.6642 2.58579C10.2891 2.21071 9.78043 2 9.25 2Z"
        fill="#4D4D70"
      />
    </svg>
  </SvgIcon>
);

export default DNAUserIcon;
