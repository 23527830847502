import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';

import FormCloseButton from '../../../components/Form/FormCloseButton';
import { selectIsJobOrderDetailLoading } from '../../../store/selectors/jobOrdersSelector';
import theme from '../../../theme';
import { ROUTE_CONSTANTS } from '../../app/routeConstants';
import { getUserDetail, setUserDetail } from '../usersListReducer';

import UserDetailLeft from './UserDetailLeft';
import UserDetailRight from './UserDetailRight';

const UserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isJobOrderDetailLoading = useSelector(selectIsJobOrderDetailLoading);

  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      dispatch(setUserDetail({}));
    } else {
      dispatch(
        getUserDetail({
          filters: [{ field: '_id', value: id, operation: 'equalsID' }],
        })
      );
    }
    return () => {
      dispatch(setUserDetail({}));
    };
  }, [dispatch, id]);

  return isJobOrderDetailLoading ? (
    <CircularProgress />
  ) : (
    <Box
      sx={{
        height: '100vh',
        padding: '25px',
        display: 'flex',
        flexDirection: 'column',
        gap: '36px',
      }}
    >
      <FormCloseButton
        onClose={() => navigate(`/${ROUTE_CONSTANTS.USERS_V2}${location.search}`)}
        message="buttonText.goBack"
      />
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            backgroundColor: `${theme?.components?.detailLeft?.palette?.backgroundColor}`,
            padding: '16px',
            display: 'flex',
            justifyContent: 'center',
            minWidth: '300px',
          }}
        >
          <UserDetailLeft />
        </Grid>

        <Grid item xs={12} md={9} sx={{ padding: 2 }}>
          <UserDetailRight />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetail;
