import { get, map } from 'lodash/fp';
import getOr from 'lodash/fp/getOr';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import { createSlice } from '@reduxjs/toolkit';

import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../utils';

import { USER_STATUSES } from './helpers';

const initialState = {
  users: [],
  isLoadingUsers: false,
  corporations: [],
  certifications: [],
  skills: [],
  isLoadingCorporations: false,
  isLoadingUpdateSkills: false,
  isUpdateProcessing: false,
  isLoadingUpdateCandidateDetails: false,
  selectedUserID: '',
  selectedCertifications: [],
  DNRData: {},
  DNAData: {},
  isProcessingPostUser: false,
  isProcessingDeactivateUser: false,
  postUserError: null,
};
export const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getUsers: (state) => {
      state.isLoadingUsers = true;
    },
    getUsersProcessed: (state, action) => {
      state.isLoadingUsers = false;

      const { count, documents } = action.payload.data;
      const mappedUsers = map((user) => {
        const { _id, id, name, role, metadata, recruiter, candidate, email, account } = user;
        const candidateStatus = get(['candidate', 'DNA'], user)
          ? 'dna'
          : getOr('inactive', 'status', candidate);

        const formatLastLogin = (timestamp) => {
          if (!timestamp) return null;
          const date = epochToDateInReadableFormat(timestamp);
          const time = epochToTimeInReadableFormat(timestamp);
          return `${date} at ${time}`;
        };
        return {
          account,
          id: id || _id,
          name,
          role,
          externalId: get(['bullhorn', 'id'], metadata),
          email,
          candidate,
          recruiter,
          phoneNumber: get(['candidate', 'phoneNumber'], user),
          status: role === UserRole.CANDIDATE ? candidateStatus : account?.status,
          location: 'Location placeholder',
          rating: user?.rating,
          lastLoginDate: formatLastLogin(user?.lastLoginDate),
        };
      }, documents);
      state.totalRowCount = count;
      state.users = mappedUsers;
    },
    getUsersError: (state) => {
      state.isLoadingUsers = false;
    },
    getUserHistory: (state) => {
      state.isLoadingUserHistory = true;
    },
    getUserHistoryProcessed: (state, action) => {
      state.isLoadingUserHistory = false;
      const { documents } = action.payload.data;
      const upcomingJobsCount = get('upcomingJobs', documents[0]) || 0;
      const DNRDetails = get(['DNRSummary', 'details'], documents[0]) || '';
      const DNADetails = get(['DNASummary', 'details'], documents[0]) || '';
      const dnrCount = get(['DNRSummary', 'count'], documents[0]);

      const selectedDNRDetails = map(
        (detail) => ({
          id: get(['DNR', '_id'], detail),
          corpId: get('corporation_id', detail),
          corpName: get('corporation_name', detail),
          reason: get(['DNR', 'reason'], detail),
          requester: get(['DNR', 'requester', 'name'], detail),
          note: get(['DNR', 'note'], detail),
          // date: '02/07/2023', //TODO add date back when BE API is ready
        }),
        DNRDetails
      );

      const selectedDNADetails = [
        {
          id: get('_id', DNADetails),
          reason: get('reason', DNADetails),
          requester: get(['requester', 'name'], DNADetails),
          date: epochToDateInReadableFormat(get('time', DNADetails)),
        },
      ];

      const DNRData = {
        type: 'DNR',
        count: dnrCount || 0,
        details: selectedDNRDetails || [],
      };

      const DNAData = {
        type: 'DNA',
        count: get('id', selectedDNADetails[0]) ? 1 : 0,
        details: get('id', selectedDNADetails[0]) ? selectedDNADetails : [],
      };

      state.DNRData = DNRData;
      state.DNAData = DNAData;
      state.selectedUpcomingCount = upcomingJobsCount;
    },
    getUserHistoryError: (state) => {
      state.isLoadingUserHistory = false;
    },

    updateDNA: (state) => {
      state.isLoadingUpdateDNA = true;
    },
    updateDNAProcessed: (state) => {
      state.isLoadingUpdateDNA = false;
    },
    updateDNAError: (state) => {
      state.isLoadingUpdateDNA = false;
    },

    updateDNR: (state) => {
      state.isLoadingUpdateDNR = true;
    },
    updateDNRProcessed: (state) => {
      state.isLoadingUpdateDNR = false;
    },
    updateDNRError: (state) => {
      state.isLoadingUpdateDNR = false;
    },

    updateCertifications: (state) => {
      state.isLoadingUpdateCertifications = true;
    },
    updateCertificationsProcessed: (state) => {
      state.isLoadingUpdateCertifications = false;
    },
    updateCertificationsError: (state) => {
      state.isLoadingUpdateCertifications = false;
    },

    updateSkills: (state) => {
      state.isLoadingUpdateSkills = true;
    },
    updateSkillsProcessed: (state) => {
      state.isLoadingUpdateSkills = false;
    },
    updateSkillsError: (state) => {
      state.isLoadingUpdateSkills = false;
    },

    getCertificationsForUsers: (state) => {
      state.isLoadingCertifications = true;
    },
    getCertificationsForUsersProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);

      const certifications = map((certification) => {
        const { _id } = certification;
        return {
          id: _id,
          ...certification,
        };
      }, documents);
      state.certifications = certifications;
      state.isLoadingCertifications = false;
    },
    getCertificationsForUsersError: (state) => {
      state.isLoadingCertifications = false;
    },

    getCorporations: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsProcessed: (state, action) => {
      state.corporations = get(['payload', 'data', 'documents'], action);
      state.isLoadingCorporations = false;
    },
    getCorporationsError: (state) => {
      state.isLoadingCorporations = false;
    },

    updateCandidateDetails: (state) => {
      state.isLoadingUpdateCandidateDetails = true;
    },
    updateCandidateDetailsProcessed: (state) => {
      state.isLoadingUpdateCandidateDetails = false;
    },
    updateCandidateDetailsError: (state) => {
      state.isLoadingUpdateCandidateDetails = false;
    },

    updateUser: (state) => {
      state.isUpdateProcessing = true;
    },
    updateUserProcessed: (state) => {
      state.isUpdateProcessing = false;
    },
    updateUserError: (state) => {
      state.isUpdateProcessing = false;
    },
    postUser: (state) => {
      state.isProcessingPostUser = true;
    },
    postEmployer: (state) => {
      state.isProcessingPostUser = true;
    },
    postUserProcessed: (state) => {
      state.isProcessingPostUser = false;
    },
    postUserError: (state, action) => {
      state.isProcessingPostUser = false;
      const messageData =
        get(['payload', 'GLOBAL', 'messageKey'], action) || 'error.generic.something.went.wrong';
      state.postUserError = messageData;
    },
    postBullhornEmployers: (state) => {
      state.isProcessingPostUser = true;
    },
    postBullhornEmployersProcessed: (state) => {
      state.isProcessingPostUser = false;
    },
    postBullhornEmployersError: (state, action) => {
      state.isProcessingPostUser = false;
      const messageData =
        get(['payload', 'GLOBAL', 'messageKey'], action) || 'error.generic.something.went.wrong';
      state.postUserError = messageData;
    },
    clearPostUserError: (state) => {
      state.postUserError = null;
    },
    deactivateUser: (state) => {
      state.isProcessingDeactivateUser = true;
    },
    deactivateUserProcessed: (state, action) => {
      state.isProcessingDeactivateUser = false;
      const id = get(['payload', 'data', 'id'], action);
      const updatedUserList = state.users.map((user) =>
        user.id === id ? { ...user, status: USER_STATUSES.DEACTIVATED } : user
      );
      state.users = updatedUserList;
    },
    deactivateUserError: (state) => {
      state.isProcessingDeactivateUser = false;
    },

    /* eslint-disable no-param-reassign */
  },
});

export const usersReducer = usersSlice.reducer;

export const {
  getUsers,
  getUsersProcessed,
  getUsersError,
  getUserHistory,
  getUserHistoryError,
  getUserHistoryProcessed,
  getCorporations,
  getCorporationsProcessed,
  getCorporationsError,
  getCertificationsForUsers,
  getCertificationsForUsersProcessed,
  getCertificationsForUsersError,
  updateDNA,
  updateDNAProcessed,
  updateDNAError,
  updateDNR,
  updateDNRProcessed,
  updateDNRError,
  updateUser,
  updateUserProcessed,
  updateUserError,
  updateCandidateDetails,
  updateCandidateDetailsProcessed,
  updateCandidateDetailsError,
  updateCertifications,
  updateCertificationsProcessed,
  updateCertificationsError,
  updateSkills,
  updateSkillsProcessed,
  updateSkillsError,
  postUser,
  postEmployer,
  postUserProcessed,
  postUserError,
  postBullhornEmployers,
  postBullhornEmployersProcessed,
  postBullhornEmployersError,
  clearPostUserError,
  deactivateUser,
  deactivateUserProcessed,
  deactivateUserError,
} = usersSlice.actions;
