import * as React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';

import AtRiskUserIcon from '../../../assets/icons/AtRiskUserIcon';
import theme from '../../../theme';

const styles = theme?.components?.userCard;

const Attendance = ({ attendance }) => {
  if (attendance === 0 || attendance === 1 || attendance === 3) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Typography sx={styles.detailSubTitleStyle}>
          {LanguageConverter('userCard.subTitle.attendance')}
        </Typography>
        <Typography sx={styles.detailSubTitleStyle}>{attendance}/3</Typography>
      </Box>
    );
  }

  if (attendance === 2) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Typography sx={styles.detailSubTitleStyle}>
          {LanguageConverter('userCard.subTitle.attendance')}
        </Typography>
        <Typography sx={styles.riskText}>
          {LanguageConverter('userCard.subTitle.atRisk')}
        </Typography>
        <AtRiskUserIcon />
      </Box>
    );
  }

  return null;
};

Attendance.propTypes = {
  attendance: PropTypes.number.isRequired,
};

export default Attendance;
