import React from 'react';
import PropTypes from 'prop-types';

import { Box, CardContent, Grid, Typography } from '@mui/material';

import { BLACK, PRIMARY_COLOR } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';

const styles = {
  textOuterBox: { flex: 1, display: 'flex', flexDirection: 'column' },
  textInnerBox: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
  textStyles: { fontSize: 14, fontFamily: PRIMARY_FONT[300] },
};

const PotentialRevenueWidget = ({
  labels,
  availableAmount,
  totalEarnedAmount,
  scheduledAmount,
}) => (
  <Grid
    container
    justifyContent="center"
    sx={{
      minWidth: '500px',
      height: '233px',
      borderRadius: '8px',
      boxShadow: 2,
    }}
  >
    <Box sx={{ width: 'inherit', maxWidth: '600px' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Typography
            gutterBottom
            variant="h2"
            sx={{ fontSize: 20, fontFamily: PRIMARY_FONT[700] }}
          >
            {labels.title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={styles.textInnerBox}>
            <Typography sx={{ fontSize: 40, fontFamily: PRIMARY_FONT[400] }}>
              ${availableAmount.toLocaleString('en-US')}
            </Typography>
          </Box>
          <Box sx={styles.textInnerBox}>
            <Typography sx={styles.textStyles}>{labels?.availableRevenue || ''}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={styles.textOuterBox}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                borderRight: `1px solid ${BLACK[70]}`,
              }}
            >
              <Typography
                color={PRIMARY_COLOR[70]}
                sx={{ fontSize: 24, fontFamily: PRIMARY_FONT[300] }}
              >
                ${totalEarnedAmount?.toLocaleString('en-US')}
              </Typography>
            </Box>
            <Box sx={styles.textInnerBox}>
              <Typography sx={styles.textStyles}>{labels?.totalRevenue || ''}</Typography>
            </Box>
            <Box sx={styles.textInnerBox}>
              <Typography sx={styles.textStyles}>
                {labels?.totalRevenueDescription || ''}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.textOuterBox}>
            <Box sx={styles.textInnerBox}>
              <Typography sx={{ fontSize: 24, fontFamily: PRIMARY_FONT[300] }}>
                ${scheduledAmount.toLocaleString('en-US')}
              </Typography>
            </Box>
            <Box sx={styles.textInnerBox}>
              <Typography sx={styles.textStyles}>{labels?.scheduled || ''}</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Box>
  </Grid>
);

PotentialRevenueWidget.propTypes = {
  labels: PropTypes.shape({
    title: PropTypes.string.isRequired,
    availableRevenue: PropTypes.string,
    totalRevenue: PropTypes.string,
    totalRevenueDescription: PropTypes.string,
    scheduled: PropTypes.string,
  }),
  availableAmount: PropTypes.number,
  totalEarnedAmount: PropTypes.number,
  scheduledAmount: PropTypes.number,
};

export default PotentialRevenueWidget;
