import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ActiveAdminIcon = () => (
  <SvgIcon>
    <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 10.09L1.5 11.58L2.18 8.77L0 6.89L2.87 6.64L4 4L5.11 6.64L8 6.89L5.8 8.77L6.45 11.58L4 10.09Z"
        fill="#1DA83B"
      />
      <path
        d="M10.6061 7.74025L17 5L23.3939 7.74025C23.7616 7.89783 24 8.25937 24 8.6594V15.5181C24 17.6712 22.8463 19.6593 20.9768 20.7275L17 23L13.0232 20.7275C11.1537 19.6593 10 17.6712 10 15.5181V8.6594C10 8.25937 10.2384 7.89783 10.6061 7.74025Z"
        stroke="#1DA83B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M20 12L16 16L14 14"
        stroke="#1DA83B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default ActiveAdminIcon;
