import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import { SHIFT_TYPES } from '../../components/UserCard/constant';

import {
  FILTER_MAPPING,
  ROLE_OPTIONS,
  SEARCH_PARAM_KEYS,
  SORT_MAPPING,
  STATUS_OPTIONS,
  TIME,
} from './UsersConstants';

export const getUniqObjectsByKey = (array) => [
  ...new Map(array.map((item) => [item?.key, item])).values(),
];

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const buildFilterPayloadFromUrl = (searchParams, prevFilters) => {
  const newFilters = Object.entries(FILTER_MAPPING).reduce(
    (acc, [paramKey, { field, operation, convertValue }]) => {
      const paramValue = searchParams.get(paramKey);
      const value = convertValue ? convertValue(paramValue) : paramValue;
      const updatedFilters = acc.filter((filter) => filter.field !== field);
      return value !== null ? [...updatedFilters, { field, operation, value }] : updatedFilters;
    },
    prevFilters
  );
  return newFilters;
};

export const buildSortPayloadFromUrl = (searchParams, prevSorts) => {
  const newSorts = Object.entries(SORT_MAPPING).reduce(
    (acc, [paramKey, { field, getIsDescending }]) => {
      const paramValue = searchParams.get(paramKey);

      const isDescending = getIsDescending(paramValue);
      const updatedSorts = acc.filter((filter) => filter.field !== field);
      return paramValue !== null
        ? [...updatedSorts, { field, descending: isDescending }]
        : updatedSorts;
    },
    prevSorts || []
  );

  return newSorts;
};

export const getMainHeaderKey = (searchParams, userRole) => {
  if (userRole === UserRole.RECRUITER || userRole === UserRole.EMPLOYER) {
    return 'userList.header.workers';
  }

  const valueMatch = ROLE_OPTIONS.find(
    (status) => searchParams.get(SEARCH_PARAM_KEYS.ROLE) === status.value
  );

  return valueMatch?.headerKey || 'userList.header.generic';
};

export const getSubHeaderKey = (searchParams) => {
  const valueMatch = STATUS_OPTIONS.find(
    (status) => searchParams.get(SEARCH_PARAM_KEYS.STATUS) === status.value
  );

  return valueMatch?.nameKey || 'userList.subHeader.generic';
};

export const formatTimePreference = (timeObj) => {
  switch (timeObj.type) {
    case SHIFT_TYPES.MORNING:
      return LanguageConverter('userDetail.preference.morning');
    case SHIFT_TYPES.EVENING:
      return LanguageConverter('userDetail.preference.evening');
    case SHIFT_TYPES.NIGHT:
      return LanguageConverter('userDetail.preference.night');
    case SHIFT_TYPES.CUSTOM: {
      const formatHour = (hour) => {
        const period = hour >= 12 ? TIME.PM : TIME.AM;
        const displayHour = hour % 12 || 12;
        return `${displayHour}${period}`;
      };
      return `${LanguageConverter('userDetail.preference.iPrefer')} ${formatHour(
        timeObj.start
      )} - ${formatHour(timeObj.end)}`;
    }
    default:
      return '';
  }
};
