import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WaitingOnboardUserIcon = () => (
  <SvgIcon>
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1238 8V3H20.0362V9H18.1238M18.1238 13H20.0362V11H18.1238M8.56175 9C11.1148 9 16.2114 10.34 16.2114 13V16H0.912109V13C0.912109 10.34 6.00868 9 8.56175 9ZM8.56175 0C9.57615 0 10.549 0.421427 11.2663 1.17157C11.9836 1.92172 12.3866 2.93913 12.3866 4C12.3866 5.06087 11.9836 6.07828 11.2663 6.82843C10.549 7.57857 9.57615 8 8.56175 8C7.54734 8 6.57448 7.57857 5.85719 6.82843C5.1399 6.07828 4.73693 5.06087 4.73693 4C4.73693 2.93913 5.1399 1.92172 5.85719 1.17157C6.57448 0.421427 7.54734 0 8.56175 0ZM8.56175 10.9C5.72182 10.9 2.7289 12.36 2.7289 13V14.1H14.3946V13C14.3946 12.36 11.4017 10.9 8.56175 10.9ZM8.56175 1.9C8.02918 1.9 7.51843 2.12125 7.14185 2.51508C6.76528 2.9089 6.55372 3.44305 6.55372 4C6.55372 4.55695 6.76528 5.0911 7.14185 5.48492C7.51843 5.87875 8.02918 6.1 8.56175 6.1C9.09431 6.1 9.60506 5.87875 9.98164 5.48492C10.3582 5.0911 10.5698 4.55695 10.5698 4C10.5698 3.44305 10.3582 2.9089 9.98164 2.51508C9.60506 2.12125 9.09431 1.9 8.56175 1.9Z"
        fill="#491599"
      />
    </svg>
  </SvgIcon>
);

export default WaitingOnboardUserIcon;
