import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseIcon from '@mui/icons-material/Close';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import TrashcanIcon from '../../../../main/assets/icons/TrashcanIcon';
import Button, { BUTTON_VARIANT } from '../../../components/Button';
import { PRIMARY_COLOR } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import {
  dateToEpoch,
  epochToDateInReadableFormat,
  epochToDayOfWeek,
  epochToTimeInReadableFormat,
} from '../../../utils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 790,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '15px',
};

const hourConst = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minConst = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
const amPm = ['AM', 'PM'];

const EditPunchModal = ({
  open,
  handleClose,
  startEpoch,
  shiftTimes,
  selectedPunches,
  placementId,
  onUpdate,
}) => {
  const [punches, setPunches] = React.useState(
    selectedPunches?.map((i, index) => ({
      index,
      start: i?.in?.stamp,
      end: i?.out?.stamp,
    }))
  );

  const [startDate, setStartDate] = React.useState(dayjs());
  const [startHour, setStartHour] = React.useState(hourConst[0]);
  const [startMin, setStartMin] = React.useState(minConst[0]);
  const [startAmPm, setStartAmPm] = React.useState(amPm[0]);

  const [endDate, setEndDate] = React.useState(dayjs());
  const [endHour, setEndHour] = React.useState(hourConst[0]);
  const [endMin, setEndMin] = React.useState(minConst[0]);
  const [endAmPm, setendAmPm] = React.useState(amPm[0]);

  const handleStartHourChange = (event) => {
    setStartHour(event.target.value);
  };
  const handleStartMinChange = (event) => {
    setStartMin(event.target.value);
  };
  const handleStartAmPmChange = (event) => {
    setStartAmPm(event.target.value);
  };

  const handleEndHourChange = (event) => {
    setEndHour(event.target.value);
  };
  const handleEndMinChange = (event) => {
    setEndMin(event.target.value);
  };
  const handleEndAmPmChange = (event) => {
    setendAmPm(event.target.value);
  };

  const dateToEpochMidnightInMs = (date) => {
    const newDate = new Date(date).setHours(0, 0, 0, 0);
    return dateToEpoch(newDate) * 1000;
  };

  const timeToMilliseconds = (hours, minutes, ampm) => {
    const conversionMap = {
      AM: hours === 12 ? 0 : hours,
      PM: hours === 12 ? 12 : hours + 12,
    };
    const newHours = conversionMap[ampm.toUpperCase()] || hours;
    return (newHours * 3600 + minutes * 60) * 1000;
  };

  const handleOnAdd = useCallback(
    (index) => {
      setPunches((prevVal) => [
        ...prevVal,
        {
          index,
          start:
            dateToEpochMidnightInMs(startDate) + timeToMilliseconds(startHour, startMin, startAmPm),
          end: dateToEpochMidnightInMs(endDate) + timeToMilliseconds(endHour, endMin, endAmPm),
        },
      ]);
    },
    [startDate, endDate, startHour, startMin, startAmPm, endHour, endMin, endAmPm]
  );

  const handleRemove = useCallback((index) => {
    setPunches((prevPunches) =>
      prevPunches
        .filter((punch) => punch.index !== index)
        .map((punch, newIndex) => ({ ...punch, index: newIndex }))
    );
  }, []);

  const handleUpdate = useCallback(() => {
    const updatedPunches = punches.map((p) => {
      const res = {
        in: p.start,
      };

      if (p?.end) {
        res.out = p?.end;
      }

      return res;
    });
    onUpdate({ placement: placementId, punches: updatedPunches });
    handleClose();
  }, [punches, placementId, handleClose, onUpdate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container justifyContent="space-between">
              <Typography sx={{ fontFamily: PRIMARY_FONT[800], fontSize: '24px' }}>
                {LanguageConverter('timekeeping.daily.editPunch.editTime')}
              </Typography>

              <IconButton color="primary" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Typography sx={{ fontFamily: PRIMARY_FONT[400], fontSize: '20px' }}>
              {`${epochToDayOfWeek(startEpoch)}, ${epochToDateInReadableFormat(startEpoch)}`}
            </Typography>
            <Typography sx={{ fontFamily: PRIMARY_FONT[400], fontSize: '20px', pb: '20px' }}>
              {dayjs(shiftTimes?.start).format('HH:mm')} - {dayjs(shiftTimes?.end).format('HH:mm')}
            </Typography>

            <Box
              sx={{
                bgcolor: 'background.paper',
                borderRadius: '20px',
                padding: '20px',
              }}
            >
              <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-start"
                gap={2}
                direction="row"
              >
                <Grid container sx={{ flexDirection: 'column', width: '530px' }}>
                  <Grid container gap={2} alignItems="center">
                    <Typography
                      sx={{ fontFamily: PRIMARY_FONT[600], fontSize: '24px', width: '40px' }}
                    >
                      In
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        renderInput={(params) => (
                          <TextField
                            sx={{ width: '200px' }}
                            {...params}
                            inputProps={{ ...params.inputProps, readOnly: true }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <FormControl sx={{ width: '65px' }}>
                      <InputLabel id="start-hour-label">
                        {LanguageConverter('timekeeping.daily.editPunch.hour')}
                      </InputLabel>
                      <Select
                        labelId="start-hour-label"
                        id="start-hour-select"
                        value={startHour}
                        label="Hour"
                        onChange={handleStartHourChange}
                      >
                        {hourConst.map((h) => (
                          <MenuItem key={h} value={h}>
                            {h}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: '65px' }}>
                      <InputLabel id="start-minute-label">
                        {LanguageConverter('timekeeping.daily.editPunch.minute')}
                      </InputLabel>

                      <Select
                        labelId="start-minute-label"
                        id="start-minute-select"
                        value={startMin}
                        label="Minute"
                        onChange={handleStartMinChange}
                      >
                        {minConst.map((m) => (
                          <MenuItem key={m} value={m}>
                            {m}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: '75px' }}>
                      <InputLabel id="start-am-pm-label">
                        {LanguageConverter('timekeeping.daily.editPunch.AMPM')}
                      </InputLabel>

                      <Select
                        labelId="start-am-pm-label"
                        id="start-am-pm-select"
                        value={startAmPm}
                        label="AM/PM"
                        onChange={handleStartAmPmChange}
                      >
                        {amPm.map((m) => (
                          <MenuItem key={m} value={m}>
                            {m}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container gap={2} alignItems="center" sx={{ paddingTop: '16px' }}>
                    <Typography
                      sx={{ fontFamily: PRIMARY_FONT[600], fontSize: '24px', width: '40px' }}
                    >
                      {LanguageConverter('timekeeping.daily.editPunch.out')}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        renderInput={(params) => (
                          <TextField
                            sx={{ width: '200px' }}
                            {...params}
                            inputProps={{ ...params.inputProps, readOnly: true }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <FormControl sx={{ width: '65px' }}>
                      <InputLabel id="end-hour-label">
                        {LanguageConverter('timekeeping.daily.editPunch.hour')}
                      </InputLabel>
                      <Select
                        labelId="end-hour-label"
                        id="end-hour-select"
                        value={endHour}
                        label="Hour"
                        onChange={handleEndHourChange}
                      >
                        {hourConst.map((h) => (
                          <MenuItem key={h} value={h}>
                            {h}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: '65px' }}>
                      <InputLabel id="end-minute-label">
                        {LanguageConverter('timekeeping.daily.editPunch.minute')}
                      </InputLabel>

                      <Select
                        labelId="end-minute-label"
                        id="end-minute-select"
                        value={endMin}
                        label="Minute"
                        onChange={handleEndMinChange}
                      >
                        {minConst.map((m) => (
                          <MenuItem key={m} value={m}>
                            {m}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: '75px' }}>
                      <InputLabel id="end-am-pm-label">
                        {LanguageConverter('timekeeping.daily.editPunch.AMPM')}
                      </InputLabel>

                      <Select
                        labelId="end-am-pm-label"
                        id="end-am-pm-select"
                        value={endAmPm}
                        label="AM/PM"
                        onChange={handleEndAmPmChange}
                      >
                        {amPm.map((m) => (
                          <MenuItem key={m} value={m}>
                            {m}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Button
                  variant={BUTTON_VARIANT.DEFAULT}
                  actionColor={PRIMARY_COLOR[70]}
                  onClick={() => handleOnAdd(punches.length)}
                  sx={{ borderRadius: '5px' }}
                >
                  {LanguageConverter('timekeeping.daily.editPunch.addPunch')}
                </Button>
              </Grid>
            </Box>
            <Divider />

            <Box
              sx={{
                bgcolor: 'background.paper',
                borderRadius: '20px',
                p: '20px',
              }}
            >
              {punches.map((punch, index) => (
                <Grid
                  key={punch.index}
                  container
                  alignItems="center"
                  gap={2}
                  sx={{ direction: 'row' }}
                >
                  <Typography sx={{ fontFamily: PRIMARY_FONT[400], fontSize: '20px' }}>
                    {`Punch ${punch.index + 1}`}
                  </Typography>
                  <Typography>
                    {`${epochToDateInReadableFormat(punch.start)}${'\xa0'}
                    ${epochToTimeInReadableFormat(punch.start)}`}
                  </Typography>
                  <Typography>-</Typography>
                  <Typography>
                    {punch.end
                      ? `${epochToDateInReadableFormat(punch.end)}${'\xa0'}
                    ${epochToTimeInReadableFormat(punch.end)}`
                      : '-- : -- '}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleRemove(index);
                    }}
                  >
                    <TrashcanIcon />
                  </IconButton>
                </Grid>
              ))}
            </Box>
            <Grid container justifyContent="flex-end">
              <Button
                variant={BUTTON_VARIANT.DEFAULT}
                actionColor={PRIMARY_COLOR[70]}
                onClick={handleUpdate}
                sx={{ borderRadius: '5px', mr: '60px', width: '100px' }}
              >
                {LanguageConverter('timekeeping.daily.editPunch.update')}
              </Button>
            </Grid>
          </Box>
        </Modal>
      </div>
    </Box>
  );
};
export default EditPunchModal;

EditPunchModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  startEpoch: PropTypes.number,
  shiftTimes: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  }),
  selectedPunches: PropTypes.arrayOf(PropTypes.shape({})),
  placementId: PropTypes.string,
  onUpdate: PropTypes.func,
};
