import React from 'react';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';

import theme from '../../../../theme';

const styles = theme?.components?.userDetail;

const WaitingVerificationCard = () => (
  <Box sx={{ width: '70%' }}>
    <Typography sx={styles.onboardingHeader}>
      {LanguageConverter('userDetail.waitingVerification.header')}
    </Typography>
  </Box>
);

export default WaitingVerificationCard;
