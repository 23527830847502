import * as React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import CoffeeCupIcon from '../../../assets/icons/CoffeeCupIcon';
import StarAndCrescentIcon from '../../../assets/icons/StarAndCrescentIcon';
import SunRiseIcon from '../../../assets/icons/SunRiseIcon';
import theme from '../../../theme';
import { BLACK } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { SHIFT_HOUR_OPTIONS, SHIFT_TYPES } from '../constant';
import { convertPreferredTimesToCustomShiftRange } from '../helpers';

const styles = theme?.components?.userCard;

const ShiftContent = ({ preferredShiftTimes }) => {
  const { customStart, customEnd } = convertPreferredTimesToCustomShiftRange(preferredShiftTimes);
  const isCustomShift = Boolean(
    preferredShiftTimes?.find((shift) => shift.type === SHIFT_TYPES.CUSTOM)
  );

  const isMorningSelected = !!preferredShiftTimes?.find((t) => t.type === SHIFT_TYPES.MORNING);
  const isEveningSelected = !!preferredShiftTimes?.find((t) => t.type === SHIFT_TYPES.EVENING);
  const isNightSelected = !!preferredShiftTimes?.find((t) => t.type === SHIFT_TYPES.NIGHT);

  const shifts = [
    {
      type: SHIFT_TYPES.MORNING,
      icon: <CoffeeCupIcon color={isMorningSelected ? BLACK[100] : BLACK[40]} />,
      isSelected: isMorningSelected,
    },
    {
      type: SHIFT_TYPES.EVENING,
      icon: <SunRiseIcon color={isEveningSelected ? BLACK[100] : BLACK[40]} />,
      isSelected: isEveningSelected,
    },
    {
      type: SHIFT_TYPES.NIGHT,
      icon: <StarAndCrescentIcon color={isNightSelected ? BLACK[100] : BLACK[40]} />,
      isSelected: isNightSelected,
    },
  ];

  return (
    <Box display="flex" alignItems="baseline" gap={1}>
      {isCustomShift ? (
        <Box>
          <Typography sx={{ ...styles.detailSubTitleStyle, fontFamily: PRIMARY_FONT[400] }}>
            {SHIFT_HOUR_OPTIONS[customStart]} - {SHIFT_HOUR_OPTIONS[customEnd]}
          </Typography>
        </Box>
      ) : (
        shifts?.map((shift) => <Box key={shift.type}>{shift?.icon}</Box>)
      )}
    </Box>
  );
};

ShiftContent.propTypes = {
  preferredShiftTimes: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ShiftContent;
