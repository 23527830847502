import React, { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { CalendarTodayOutlined } from '@mui/icons-material';
import LunchDiningOutlinedIcon from '@mui/icons-material/LunchDiningOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Box, Tooltip } from '@mui/material';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid-pro';

import StatusIcon from '../../../../main/assets/icons/StatusIconInFilters.svg';
import WaeButton from '../../../../main/components/Button';
import FreeTextSearchFilter, {
  SEARCHBAR_BACKGROUND,
} from '../../../../main/components/FreeTextSearchFilter';
import { SelectDropdownFilter } from '../../../../main/components/SelectDropdownFilter/SelectDropdownFilter';
import DetectiveIcon from '../../../assets/icons/DetectiveIcon';
import ImbalancedIcon from '../../../assets/icons/ImbalancedIcon';
import { BUTTON_VARIANT } from '../../../components/Button';
import {
  selectDailyTimecards,
  selectIsDailyTimecardsLoading,
  selectIsReloadDailyTimecards,
  selectIsTimecardUpdateHoursLoadingLoading,
  selectTotalDailyTimecardsRowCount,
} from '../../../store/selectors/timeSheetSelector';
import { BLACK, PRIMARY_COLOR } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { epochToTimeInReadableFormat } from '../../../utils';
import {
  addLunchTimecardsByPlacementIds,
  addMissingShiftTimecardsByPlacementIds,
  approveTimecardsByPlacementIds,
  finalizeTimecardsByPlacementIds,
  getDailyTimecards,
  resolveTimecardsByPlacementIds,
  unFinalizeTimecardsByPlacementIds,
  updateDailyTimekeepingPunches,
  updateTimecardHours,
} from '../reducer';

import TimekeepingDayFilter from './components/TimekeepingDayFilter';
import EditPunchModal from './EditPunchModal';

const DailyTimekeeping = () => {
  const statusOptions = [
    { value: 'pending', name: LanguageConverter('timekeeping.status.pending') },
    { value: 'resolved', name: LanguageConverter('timekeeping.status.resolved') },
    { value: 'disputed', name: LanguageConverter('timekeeping.status.disputed') },
  ];

  const tagOptions = [
    { value: 'missingLunch', name: LanguageConverter('timekeeping.daily.tags.missingLunch') },
    { value: 'missingShift', name: LanguageConverter('timekeeping.daily.tags.missingShift') },
    { value: 'imbalanced', name: LanguageConverter('timekeeping.daily.tags.imbalanced') },
    { value: 'looksGood', name: LanguageConverter('timekeeping.daily.tags.looksGood') },
    { value: 'suspicious', name: LanguageConverter('timekeeping.daily.tags.suspicious') },
    { value: 'other', name: LanguageConverter('timekeeping.daily.tags.other') },
  ];

  const dispatch = useDispatch();
  const [startEpoch, setStartEpoch] = useState(Date.now());
  const [punchesModalOpen, setPunchesModalOpen] = useState(false);
  const [selectedPunchesRow, setSelectedPunchesRow] = useState(null);
  const [selectedHoursRow, setSelectedHoursRow] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [pageSize, setPageSize] = useState(5);

  const rows = useSelector(selectDailyTimecards);
  const totalRowCount = useSelector(selectTotalDailyTimecardsRowCount);
  const isLoading = useSelector(selectIsDailyTimecardsLoading);
  const isTimecardUpdateHoursLoading = useSelector(selectIsTimecardUpdateHoursLoadingLoading);
  const isReloadDailyTimecards = useSelector(selectIsReloadDailyTimecards);

  const [status, setStatus] = useState(null);
  const [tag, setTag] = useState(null);
  const [positionName, setPositionName] = useState(null);
  const [filterAndSortData, setFilterAndSortData] = useState({
    page: 0,
    pageSize,
    startEpoch,
    filters: [],
  });

  const processRowUpdate = (newRow, oldRow) => {
    if (!isTimecardUpdateHoursLoading) {
      if (newRow.hours !== oldRow.hours) {
        dispatch(updateTimecardHours({ placement: newRow.placementId, hours: newRow.hours }));
        return oldRow;
      }
    }

    return oldRow;
  };

  const formatPunchTime = (punch) => {
    if (punch?.in?.stamp && punch?.out?.stamp) {
      return `${epochToTimeInReadableFormat(punch.in.stamp)} - ${epochToTimeInReadableFormat(
        punch.out.stamp
      )}`;
    }

    if (punch?.in?.stamp) {
      return `${epochToTimeInReadableFormat(punch.in.stamp)} - --:-- `;
    }

    return '--:--';
  };

  const columns = [
    {
      field: 'tag',
      headerName: LanguageConverter('timekeeping.daily.columnHeader.tags'),
      width: 60,
      renderCell: (params) => {
        const cellTag = params.value;
        return (
          <Box display="flex" alignItems="center">
            {cellTag === 'looksGood' && (
              <Tooltip title={LanguageConverter('timekeeping.daily.tags.looksGood')}>
                <ThumbUpOffAltIcon width={18} height={18} />
              </Tooltip>
            )}
            {cellTag === 'suspicious' && (
              <Tooltip title={LanguageConverter('timekeeping.daily.tags.suspicious')}>
                <span>
                  <DetectiveIcon width={18} height={18} />
                </span>
              </Tooltip>
            )}
            {cellTag === 'missingLunch' && (
              <Tooltip title={LanguageConverter('timekeeping.daily.tags.missingLunch')}>
                <LunchDiningOutlinedIcon width={18} height={18} />
              </Tooltip>
            )}
            {cellTag === 'missingShift' && (
              <Tooltip title={LanguageConverter('timekeeping.daily.tags.missingShift')}>
                <CalendarTodayOutlined width={18} height={18} />
              </Tooltip>
            )}
            {cellTag === 'imbalanced' && (
              <Tooltip title={LanguageConverter('timekeeping.daily.tags.imbalanced')}>
                <span>
                  <ImbalancedIcon width={18} height={18} />
                </span>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: LanguageConverter('timekeeping.daily.columnHeader.status'),
      width: 90,
      renderCell: (params) => (
        <Box sx={{ background: BLACK[20], borderRadius: '4px', padding: '6px' }}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: LanguageConverter('timekeeping.daily.columnHeader.employeeName'),
      width: 150,
    },
    {
      field: 'corporation',
      headerName: LanguageConverter('timekeeping.daily.columnHeader.corpName'),
      width: 150,
    },
    {
      field: 'title',
      headerName: LanguageConverter('timekeeping.daily.columnHeader.positionTitle'),
      width: 120,
    },
    {
      field: 'shift',
      headerName: LanguageConverter('timekeeping.daily.columnHeader.shiftTimes'),
      width: 120,
      renderCell: (params) => {
        const { start, end } = params.value || {};
        return (
          <span>
            {dayjs(start).format('HH:mm')} - {dayjs(end).format('HH:mm')}
          </span>
        );
      },
    },
    {
      field: 'punches',
      headerName: LanguageConverter('timekeeping.daily.columnHeader.punches'),
      width: 200,
      editable: true,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            cursor: 'pointer',
            gap: '6px',
          }}
        >
          {params.value.map((punch, index) => (
            <div key={`key_${index + 1}`}>{formatPunchTime(punch)}</div>
          ))}
        </Box>
      ),
    },
    {
      field: 'hours',
      headerName: LanguageConverter('timekeeping.daily.columnHeader.hours'),
      type: 'number',
      width: 90,
      editable: true,
      renderCell: (params) => {
        if (isTimecardUpdateHoursLoading && selectedHoursRow.id === params.row.id)
          return <span> Updating... </span>;
        return params.value === 0 ? (
          <span
            style={{
              fontStyle: 'italic',
              cursor: 'pointer',
              color: PRIMARY_COLOR[50],
              textDecoration: 'underline',
            }}
          >
            0.0
          </span>
        ) : (
          <strong>{params.value} hrs</strong>
        );
      },
    },
  ];

  const handleBackButtonClick = () => {
    const date = new Date(startEpoch);
    date.setDate(date.getDate() - 1);
    const newEpoch = date.getTime();
    setStartEpoch(newEpoch);
    setFilterAndSortData((prev) => ({ ...prev, startEpoch: newEpoch }));
  };

  const handleForwardButtonClick = () => {
    const date = new Date(startEpoch);
    date.setDate(date.getDate() + 1);
    const newEpoch = date.getTime();
    setStartEpoch(newEpoch);
    setFilterAndSortData((prev) => ({ ...prev, startEpoch: newEpoch }));
  };

  const handleOpenModal = useCallback((rowData) => {
    setSelectedPunchesRow(rowData);
    setPunchesModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setPunchesModalOpen(false);
    setSelectedPunchesRow(null);
  }, []);

  const handleStatusChange = useCallback(
    (newStatus) => {
      setStatus(newStatus);

      const excludedStatusFilters = filterAndSortData.filters.filter(
        (filter) => filter.field !== 'timecard.status'
      );
      if (!newStatus) {
        setFilterAndSortData((prev) => ({ ...prev, filters: excludedStatusFilters }));
      } else {
        const includedStatusFilters = [
          ...excludedStatusFilters,
          { value: newStatus, field: 'timecard.status', operation: 'equals' },
        ];
        setFilterAndSortData((prev) => ({ ...prev, filters: includedStatusFilters }));
      }
    },
    [filterAndSortData]
  );

  const handleTagChange = useCallback(
    (newTag) => {
      setTag(newTag);

      const excludedTagFilters = filterAndSortData.filters.filter(
        (filter) => filter.field !== 'timecard.tag'
      );
      if (!newTag) {
        setFilterAndSortData((prev) => ({ ...prev, filters: excludedTagFilters }));
      } else {
        const includedTagFilters = [
          ...excludedTagFilters,
          { value: newTag, field: 'timecard.tag', operation: 'equals' },
        ];
        setFilterAndSortData((prev) => ({ ...prev, filters: includedTagFilters }));
      }
    },
    [filterAndSortData]
  );

  const handlePositionSearchChange = useCallback(
    (newPositionFilter) => {
      const positionSearchTerm = newPositionFilter?.[0]?.value;
      setPositionName(positionSearchTerm);

      const excludedPositionFilters = filterAndSortData.filters.filter(
        (filter) => filter.field !== 'jobOrder.name'
      );
      if (!positionSearchTerm) {
        setFilterAndSortData((prev) => ({ ...prev, filters: excludedPositionFilters }));
      } else {
        const includedPositionFilters = [
          ...excludedPositionFilters,
          { value: positionSearchTerm, field: 'jobOrder.name', operation: 'icontains' },
        ];
        setFilterAndSortData((prev) => ({ ...prev, filters: includedPositionFilters }));
      }
    },
    [filterAndSortData]
  );

  const handleBulkApprove = useCallback(() => {
    if (selectedRowIds?.length > 0) {
      const placementIds = rows
        .filter((r) => selectedRowIds.includes(r.id))
        .map((r) => r.placementId);
      dispatch(approveTimecardsByPlacementIds({ placements: placementIds }));
    }
  }, [dispatch, rows, selectedRowIds]);

  const handleBulkResolve = useCallback(() => {
    if (selectedRowIds?.length > 0) {
      const placementIds = rows
        .filter((r) => selectedRowIds.includes(r.id))
        .map((r) => r.placementId);
      dispatch(resolveTimecardsByPlacementIds({ placements: placementIds }));
    }
  }, [dispatch, rows, selectedRowIds]);

  const handleBulkFinalize = useCallback(() => {
    if (selectedRowIds?.length > 0) {
      const placementIds = rows
        .filter((r) => selectedRowIds.includes(r.id))
        .map((r) => r.placementId);
      dispatch(finalizeTimecardsByPlacementIds({ placements: placementIds }));
    }
  }, [dispatch, rows, selectedRowIds]);

  const handleBulkUnFinalize = useCallback(() => {
    if (selectedRowIds?.length > 0) {
      const placementIds = rows
        .filter((r) => selectedRowIds.includes(r.id))
        .map((r) => r.placementId);
      dispatch(unFinalizeTimecardsByPlacementIds({ placements: placementIds }));
    }
  }, [dispatch, rows, selectedRowIds]);

  const handleBulkAddLunch = useCallback(() => {
    if (selectedRowIds?.length > 0) {
      const placementIds = rows
        .filter((r) => selectedRowIds.includes(r.id))
        .map((r) => r.placementId);
      dispatch(addLunchTimecardsByPlacementIds({ placements: placementIds }));
    }
  }, [dispatch, rows, selectedRowIds]);

  const handleBulkAddMissingShift = useCallback(() => {
    if (selectedRowIds?.length > 0) {
      const placementIds = rows
        .filter((r) => selectedRowIds.includes(r.id))
        .map((r) => r.placementId);
      dispatch(addMissingShiftTimecardsByPlacementIds({ placements: placementIds }));
    }
  }, [dispatch, rows, selectedRowIds]);

  const handleSelectionChange = useCallback((pageSelectedRowIds) => {
    setSelectedRowIds(pageSelectedRowIds);
  }, []);
  const handleUpdate = useCallback(
    (newPunches) => {
      dispatch(updateDailyTimekeepingPunches(newPunches));
    },
    [dispatch]
  );

  const previousFiltersRef = useRef();
  useEffect(() => {
    if (!isLoading) {
      const payload = {
        timestamp: filterAndSortData.startEpoch,
        filters: filterAndSortData.filters,
        limit: filterAndSortData.pageSize,
        page: filterAndSortData.page,
      };
      if (!isEqual(previousFiltersRef.current, payload) || isReloadDailyTimecards) {
        previousFiltersRef.current = payload;
        dispatch(getDailyTimecards(payload));
      }
    }
  }, [dispatch, isLoading, filterAndSortData, isReloadDailyTimecards]);

  const Toolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '12px' }}>
        <WaeButton
          variant={BUTTON_VARIANT.DEFAULT}
          actionColor={PRIMARY_COLOR[70]}
          onClick={handleBulkApprove}
        >
          {LanguageConverter('buttonText.approve')}
        </WaeButton>
        <WaeButton
          variant={BUTTON_VARIANT.DEFAULT}
          actionColor={PRIMARY_COLOR[70]}
          onClick={handleBulkResolve}
        >
          {LanguageConverter('buttonText.resolve')}
        </WaeButton>
        <WaeButton
          variant={BUTTON_VARIANT.DEFAULT}
          actionColor={PRIMARY_COLOR[70]}
          onClick={handleBulkFinalize}
        >
          {LanguageConverter('buttonText.finalize')}
        </WaeButton>
        <WaeButton
          variant={BUTTON_VARIANT.DEFAULT}
          actionColor={PRIMARY_COLOR[70]}
          onClick={handleBulkUnFinalize}
        >
          {LanguageConverter('buttonText.unFinalize')}
        </WaeButton>
        <WaeButton
          variant={BUTTON_VARIANT.DEFAULT}
          actionColor={PRIMARY_COLOR[70]}
          onClick={handleBulkAddLunch}
          disabled
        >
          {LanguageConverter('buttonText.addLunch')}
        </WaeButton>
        <WaeButton
          variant={BUTTON_VARIANT.DEFAULT}
          actionColor={PRIMARY_COLOR[70]}
          onClick={handleBulkAddMissingShift}
          disabled
        >
          {LanguageConverter('buttonText.addMissingShift')}
        </WaeButton>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <TimekeepingDayFilter
          startEpoch={startEpoch}
          handleBackButtonClick={handleBackButtonClick}
          handleForwardButtonClick={handleForwardButtonClick}
        />
        <Box sx={{ display: 'fex', flexDirection: 'row', gap: '6px' }}>
          <Box>
            <SelectDropdownFilter
              disabled={isLoading}
              placeholder="Status"
              onValueChange={handleStatusChange}
              options={statusOptions}
              getOptionLabel={(option) => option.name}
              startAdornmentIcon={
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                  alt="Status"
                  src={StatusIcon}
                />
              }
              initialValue={status}
            />
          </Box>
          <Box>
            <SelectDropdownFilter
              disabled={isLoading}
              placeholder="Tags"
              onValueChange={handleTagChange}
              options={tagOptions}
              getOptionLabel={(option) => option.name}
              startAdornmentIcon={
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                  alt="Status"
                  src={StatusIcon}
                />
              }
              initialValue={tag}
            />
          </Box>
          <Box>
            <FreeTextSearchFilter
              disabled={isLoading}
              placeholder="Position Name"
              onValueChange={handlePositionSearchChange}
              field="name"
              operation="icontains"
              background={SEARCHBAR_BACKGROUND.DEFAULT}
              initialValue={{ value: positionName }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          loading={isLoading}
          pagination
          onPageChange={(page) => {
            setFilterAndSortData((prevVal) => ({
              ...prevVal,
              page,
            }));
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setFilterAndSortData((prevVal) => ({
              ...prevVal,
              page: 0,
              pageSize: newPageSize,
            }));
          }}
          rowCount={totalRowCount}
          rowsPerPageOptions={[1, 5, 10]}
          components={{ Toolbar }}
          isCellEditable={(params) => params.field === 'hours' && params.row.hours === 0}
          onCellDoubleClick={(params, event) => {
            if (params.field === 'punches') {
              event.stopPropagation();
              handleOpenModal(params.row);
            }
            if (params.field === 'hours') {
              setSelectedHoursRow(params.row);
            }
          }}
          processRowUpdate={processRowUpdate}
          getRowHeight={(params) => {
            const punchesCount = params.model.punches.length || 1;
            return Math.max(40, punchesCount * 30);
          }}
          checkboxSelection
          onSelectionModelChange={handleSelectionChange}
          selectionModel={selectedRowIds}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              fontFamily: PRIMARY_FONT[400],
              fontWeight: 'bold',
              fontSize: '16px',
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          rowsLoadingMode="server"
          paginationMode="server"
        />
        {punchesModalOpen && (
          <EditPunchModal
            open={punchesModalOpen}
            handleClose={handleCloseModal}
            startEpoch={startEpoch}
            shiftTimes={selectedPunchesRow?.shift || {}}
            selectedPunches={selectedPunchesRow?.punches || []}
            placementId={selectedPunchesRow?.placementId}
            onUpdate={handleUpdate}
          />
        )}
      </Box>
    </Box>
  );
};

export default DailyTimekeeping;
