import React from 'react';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';

import theme from '../../../../theme';

const styles = theme?.components?.userDetail;

const OnboardingIncompleteCard = () => (
  <Box sx={{ width: '70%' }}>
    <Typography sx={styles.onboardingHeader}>
      {LanguageConverter('userDetail.onboardingIncomplete.header')}
    </Typography>
    <Typography sx={styles.onboardingSubText}>
      {LanguageConverter('userDetail.onboardingIncomplete.title')}
    </Typography>
    <Box component="ul" sx={{ pl: 2, m: 0 }}>
      <Typography component="li" sx={styles.onboardingSubText}>
        {LanguageConverter('userDetail.onboardingIncomplete.line1')}
      </Typography>
      <Typography component="li" sx={styles.onboardingSubText}>
        {LanguageConverter('userDetail.onboardingIncomplete.line2')}
      </Typography>
      <Typography component="li" sx={styles.onboardingSubText}>
        {LanguageConverter('userDetail.onboardingIncomplete.line3')}
      </Typography>
    </Box>
    <Typography sx={styles.onboardingSubText}>
      {LanguageConverter('userDetail.onboardingIncomplete.end')}
    </Typography>
  </Box>
);

export default OnboardingIncompleteCard;
