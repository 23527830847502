import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  widgetData: null,
  jobOrders: null,
  isWidgetDataLoading: false,
  isJobOrdersLoading: false,
  widgetError: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    getWidgetData: (state) => {
      state.isWidgetDataLoading = true;
      state.widgetError = null;
    },
    getWidgetDataProcessed: (state, action) => {
      state.isWidgetDataLoading = false;
      state.widgetData = action.payload.data;
    },
    getWidgetDataError: (state, action) => {
      state.isWidgetDataLoading = false;
      state.widgetError = action.payload;
    },
    getJobOrdersForDashboard: (state) => {
      state.isJobOrdersLoading = true;
    },
    getJobOrdersForDashboardProcessed: (state, action) => {
      state.isJobOrdersLoading = false;
      state.jobOrders = action.payload.data;
    },
    getJobOrdersForDashboardError: (state) => {
      state.isJobOrdersLoading = false;
      state.jobOrders = initialState.jobOrders;
    },
  },
});

export const dashboardReducer = dashboardSlice.reducer;

export const {
  getWidgetData,
  getWidgetDataError,
  getWidgetDataProcessed,
  getJobOrdersForDashboard,
  getJobOrdersForDashboardProcessed,
  getJobOrdersForDashboardError,
} = dashboardSlice.actions;
