import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import BusinessIcon from '@mui/icons-material/Business';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';

import BriefCaseIcon from '../../../assets/icons/BriefCaseIcon';
import { ROUTE_CONSTANTS } from '../../../views/app/routeConstants';

const Positions = {
  url: ROUTE_CONSTANTS.POSITION_TEMPLATES,
  Icon: PersonAddOutlinedIcon,
  label: 'Positions',
  id: 'positions',
};
const JobOrders = {
  url: ROUTE_CONSTANTS.JOB_ORDERS,
  Icon: BriefCaseIcon,
  id: 'jobOrders',
  label: 'Job Orders',
};
const Timekeeping = {
  url: ROUTE_CONSTANTS.TIMEKEEPING,
  Icon: QueryBuilderOutlinedIcon,
  label: 'Timekeeping',
  id: 'timekeeping',
};
const Users = {
  url: ROUTE_CONSTANTS.USERS,
  Icon: PeopleOutlinedIcon,
  label: 'Users',
  id: 'users',
};
const Corporations = {
  url: ROUTE_CONSTANTS.CORPORATIONS,
  Icon: BusinessIcon,
  label: 'Corporations',
  id: 'corporations',
};
const SkillsAndCerts = {
  url: ROUTE_CONSTANTS.SKILLS_AND_CERTS,
  Icon: FactCheckIcon,
  label: 'Skills & Certs',
  id: 'skillsAndCerts',
};

const Reporting = {
  url: ROUTE_CONSTANTS.REPORTING,
  Icon: InsertChartIcon,
  label: 'Reporting',
  id: 'reporting',
};

const Dashboard = {
  url: ROUTE_CONSTANTS.DASHBOARD,
  Icon: InsertChartIcon,
  label: 'Dashboard',
  id: 'dashboard',
};

const { ADMIN, RECRUITER, EMPLOYER } = UserRole;

const generateNavigationMenus = (role, dashboardEnabled) => {
  let menus = [];
  if (role === ADMIN) {
    menus = [Dashboard, JobOrders, Timekeeping, Reporting];
  } else if (role === RECRUITER) {
    menus = [Dashboard, JobOrders, Timekeeping];
  } else if (role === EMPLOYER) {
    menus = [JobOrders, Timekeeping];
  }
  if (!dashboardEnabled) {
    menus = menus.filter((menu) => menu.id !== 'dashboard');
  }
  return menus;
};

const generateDropDownMenus = (role) => {
  if (role === ADMIN || role === RECRUITER) {
    return [Users, Positions, Corporations, SkillsAndCerts];
  }
  return [];
};

export { generateDropDownMenus, generateNavigationMenus };
