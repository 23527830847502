export const SORT_VALUES = {
  ASC: 'asc',
  DESC: 'desc',
};

export const INITIAL_FILTER_SORT_PAGE = {
  filters: [],
  pageSize: 10,
  page: 0,
  sortBy: [],
};

export const SEARCH_PARAM_KEYS = {
  ROLE: 'role',
  STATUS: 'status',
  NAME: 'name',
  LOGIN_SORT: 'loginsort',
};

export const FILTER_FIELDS_TO_OPERATION = {
  status: { field: 'status', operation: 'equals' },
  role: { field: 'role', operation: 'equals' },
  name: { field: 'name', operation: 'icontains' },
};

export const FILTER_MAPPING = {
  [SEARCH_PARAM_KEYS.STATUS]: {
    field: FILTER_FIELDS_TO_OPERATION.status.field,
    operation: FILTER_FIELDS_TO_OPERATION.status.operation,
  },
  [SEARCH_PARAM_KEYS.ROLE]: {
    field: FILTER_FIELDS_TO_OPERATION.role.field,
    operation: FILTER_FIELDS_TO_OPERATION.role.operation,
  },
  [SEARCH_PARAM_KEYS.NAME]: {
    field: FILTER_FIELDS_TO_OPERATION.name.field,
    operation: FILTER_FIELDS_TO_OPERATION.name.operation,
  },
};

export const SORT_MAPPING = {
  [SEARCH_PARAM_KEYS.LOGIN_SORT]: {
    field: 'lastLoginDate',
    getIsDescending: (v) => v === SORT_VALUES.DESC,
  },
};

export const ROLE_OPTIONS = [
  {
    value: 'candidate',
    name: 'Candidate',
    nameKey: 'userRole.worker',
    headerKey: 'userList.header.workers',
  },
  {
    value: 'recruiter',
    name: 'Recruiter',
    nameKey: 'userRole.recruiter',
    headerKey: 'userList.header.recruiters',
  },
  {
    value: 'employer',
    name: 'Employer',
    nameKey: 'userRole.employer',
    headerKey: 'userList.header.employers',
  },
  {
    value: 'admin',
    name: 'Admin',
    nameKey: 'userRole.admin',
    headerKey: 'userList.header.admins',
  },
];

export const STATUS_OPTIONS = [
  { value: 'active', name: 'Active', nameKey: 'user.status.active' },
  { value: 'deactivated', name: 'Deactivated', nameKey: 'user.status.deactivated' },
  { value: 'DNA', name: 'DNA', nameKey: 'user.status.DNA' },
  {
    value: 'onboardingIncomplete',
    name: 'Currently Onboarding',
    nameKey: 'user.status.onboardingIncomplete.full',
  },
  { value: 'suspended', name: 'Suspended', nameKey: 'user.status.suspended' },
  { value: 'unverified', name: 'Unverified', nameKey: 'user.status.unverified' },
];

export const TIME = {
  AM: 'am',
  PM: 'pm',
};

export const USER_STATUS = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
  DNA: 'DNA',
  ONBOARDING_INCOMPLETE: 'onboardingIncomplete',
  SUSPENDED: 'suspended',
  UNVERIFIED: 'unverified',
};
