import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const UnverifiedAdminIcon = () => (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.60608 5.74025L12 3L18.3939 5.74025C18.7616 5.89783 19 6.25937 19 6.6594V13.5181C19 15.6712 17.8463 17.6593 15.9768 18.7275L12 21L8.02317 18.7275C6.15372 17.6593 5 15.6712 5 13.5181V6.6594C5 6.25937 5.2384 5.89783 5.60608 5.74025Z"
        stroke="#491599"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path d="M11 12V7H13V13H11M11 17H13V15H11" fill="#491599" />
    </svg>
  </SvgIcon>
);

export default UnverifiedAdminIcon;
