import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ImbalancedIcon = () => (
  <SvgIcon>
    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.9288 12.625L23.9288 2.625C23.8407 2.40473 23.6772 2.22295 23.4674 2.11212C23.2577 2.0013 23.0154 1.96865 22.7838 2.02L15 3.75V1C15 0.734784 14.8947 0.48043 14.7071 0.292893C14.5196 0.105357 14.2652 0 14 0C13.7348 0 13.4804 0.105357 13.2929 0.292893C13.1054 0.48043 13 0.734784 13 1V4.1975L4.78252 6.02375C4.62408 6.05875 4.47654 6.13177 4.35263 6.23652C4.22871 6.34127 4.13215 6.4746 4.07127 6.625V6.6325L0.0712692 16.625C0.0234181 16.7442 -0.000781554 16.8716 1.92435e-05 17C1.92435e-05 19.9137 3.06752 21 5.00002 21C6.93252 21 10 19.9137 10 17C10.0008 16.8716 9.97662 16.7442 9.92877 16.625L6.36502 7.72125L13 6.25V22H11C10.7348 22 10.4804 22.1054 10.2929 22.2929C10.1054 22.4804 10 22.7348 10 23C10 23.2652 10.1054 23.5196 10.2929 23.7071C10.4804 23.8946 10.7348 24 11 24H17C17.2652 24 17.5196 23.8946 17.7071 23.7071C17.8947 23.5196 18 23.2652 18 23C18 22.7348 17.8947 22.4804 17.7071 22.2929C17.5196 22.1054 17.2652 22 17 22H15V5.8025L21.375 4.3875L18.0713 12.625C18.0234 12.7442 17.9992 12.8716 18 13C18 15.9137 21.0675 17 23 17C24.9325 17 28 15.9137 28 13C28.0008 12.8716 27.9766 12.7442 27.9288 12.625ZM5.00002 19C4.05877 19 2.15502 18.5487 2.00877 17.17L5.00002 9.6925L7.99127 17.17C7.84502 18.5487 5.94127 19 5.00002 19ZM23 15C22.0588 15 20.155 14.5487 20.0088 13.17L23 5.6925L25.9913 13.17C25.845 14.5487 23.9413 15 23 15Z"
        fill="#343330"
      />
    </svg>
  </SvgIcon>
);

export default ImbalancedIcon;
