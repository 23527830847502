import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ActiveUserIcon = () => (
  <SvgIcon>
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 0.5C15.0609 0.5 16.0783 0.921427 16.8284 1.67157C17.5786 2.42172 18 3.43913 18 4.5C18 5.56087 17.5786 6.57828 16.8284 7.32843C16.0783 8.07857 15.0609 8.5 14 8.5C12.9391 8.5 11.9217 8.07857 11.1716 7.32843C10.4214 6.57828 10 5.56087 10 4.5C10 3.43913 10.4214 2.42172 11.1716 1.67157C11.9217 0.921427 12.9391 0.5 14 0.5ZM14 2.4C13.443 2.4 12.9089 2.62125 12.5151 3.01508C12.1212 3.4089 11.9 3.94305 11.9 4.5C11.9 5.05695 12.1212 5.5911 12.5151 5.98492C12.9089 6.37875 13.443 6.6 14 6.6C15.16 6.6 16.1 5.66 16.1 4.5C16.1 3.34 15.16 2.4 14 2.4ZM14 9.5C16.67 9.5 22 10.83 22 13.5V16.5H6V13.5C6 10.83 11.33 9.5 14 9.5ZM14 11.4C11 11.4 7.9 12.86 7.9 13.5V14.6H20.1V13.5C20.1 12.86 16.97 11.4 14 11.4ZM4 9.78L1.5 11.27L2.18 8.46L0 6.58L2.87 6.33L4 3.69L5.11 6.33L8 6.58L5.8 8.46L6.45 11.27L4 9.78Z"
        fill="#1DA83B"
      />
    </svg>
  </SvgIcon>
);

export default ActiveUserIcon;
