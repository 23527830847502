import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import Zendesk from 'react-zendesk';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import AppTraining from '../../../main-deprecated/components/AppTraining';
import selectUser, {
  selectIsProcessingAppTraining,
} from '../../../main-deprecated/store/selectors/appSelector';
import launchDarklyToggles from '../../../main-deprecated/utils/launchDarklyToggles';
import { history } from '../../../main-deprecated/utils/navigator';
import { updateUserData } from '../../../main-deprecated/views/app';
import MobileDirect from '../../../main-deprecated/views/Candidate/mobileDirect';
import CorporationList from '../../../main-deprecated/views/corporations/corporationList/CorporationList';
import CreateCorporation from '../../../main-deprecated/views/corporations/createCorporation/CreateCorporation';
import ForgotMyPassword from '../../../main-deprecated/views/forgotMyPassword';
import Login from '../../../main-deprecated/views/login';
import MaintenancePage from '../../../main-deprecated/views/maintenance';
import PasswordVerificationOrResetPage from '../../../main-deprecated/views/PasswordVerificationOrReset/PasswordVerificationOrResetPage';
import PositionTemplateList from '../../../main-deprecated/views/positionTemplate/positionTemplateList/PositionTemplateList';
import Register from '../../../main-deprecated/views/register/Register';
import Settings from '../../../main-deprecated/views/settings';
import SkillsAndCerts from '../../../main-deprecated/views/skillsAndCerts';
import TimekeepingGridView from '../../../main-deprecated/views/timekeeping/timekeepingGridView/TimekeepingGridView';
import UserList from '../../../main-deprecated/views/users/userList/UserList';
import Snackbar from '../../components/Snackbar';
import { getItemFromLocalStorage, LOCAL_STORAGE_KEYS } from '../../store/utils';
import { setLanguage } from '../../utils/internationalization';
import Dashboard from '../dashboard/Dashboard';
import JobOrderDetail from '../jobOrders/jobOrderDetail';
import JobOrders from '../jobOrders/JobOrders';
import JobOrdersCreate from '../jobOrdersCreate/JobOrdersCreate';
import Reporting from '../reporting/Reporting';
import UserDetail from '../usersList/userDetail/UserDetail';
import UsersList from '../usersList/UsersList';

import Navigation from './Navigation';
import { ROUTE_CONSTANTS, searchParamOptions } from './routeConstants';

const AppRouter = ({ flags }) => {
  const [dictData, setDictData] = useState({});
  const user = useSelector(selectUser);
  const isProcessingAppTraining = useSelector(selectIsProcessingAppTraining);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      const userData = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.userDataKey);

      if (userData) {
        dispatch(updateUserData(userData));
        // TODO: (TICKET PENDING) Longer term, we'll want just the token and we'll do a lookup to get the
        // rest of the user data if it is a valid token. That way, either the token is invalid and
        // user is forced to reauth, or the token is valid and we get fresh data for the user.
      }
    }
  }, [dispatch, user]);

  const maintenance = launchDarklyToggles(flags, 'isMaintenanceModeEnabled');
  const timesheetToggle = launchDarklyToggles(flags, 'isTimesheetEnabled');
  const isAdminMetricsPageEnabled = launchDarklyToggles(flags, 'isAdminMetricsPageEnabled');
  const isEnterpriseDashboardEnabled = launchDarklyToggles(flags, 'isEnterpriseDashboardEnabled');
  const token = get('token', user);
  const role = token && get('role', user);
  const { ADMIN, EMPLOYER, RECRUITER, CANDIDATE } = UserRole;
  const isAdmin = role === ADMIN;
  const isEmployer = role === EMPLOYER;
  const isRecruiter = role === RECRUITER;
  const isEmployee = role === CANDIDATE;
  const { REACT_APP_EMPLOYER_ZENDESK_KEY: EMPLOYER_ZENDESK_KEY } = process.env;

  // Set the language in the internationalization file
  useEffect(() => {
    setLanguage(setDictData);
  }, []);

  if (Object.keys(dictData).length !== 0) {
    return (
      <IntlProvider messages={dictData} locale="en" defaultLocale="en">
        <HistoryRouter history={history}>
          <Snackbar />
          {token && <AppTraining user={user} loading={isProcessingAppTraining} />}
          <Zendesk zendeskKey={EMPLOYER_ZENDESK_KEY} />
          {maintenance ? (
            <MaintenancePage />
          ) : (
            <Routes>
              <Route path={ROUTE_CONSTANTS.BASE} element={<Login />} />
              <Route path={ROUTE_CONSTANTS.REGISTER} element={<Register />} />
              <Route
                path={ROUTE_CONSTANTS.ACCOUNT_VERIFICATION_QUERY_INFORMATION}
                element={<PasswordVerificationOrResetPage type="accountVerification" />}
              />
              <Route
                path={ROUTE_CONSTANTS.PASSWORD_RESET_QUERY_INFORMATION}
                element={<PasswordVerificationOrResetPage type="passwordReset" />}
              />
              <Route path={ROUTE_CONSTANTS.FORGOT_MY_PASSWORD} element={<ForgotMyPassword />} />
              {/* Admin, Employer, Recruiter View */}
              {(isAdmin || isEmployer || isRecruiter) && (
                <Route element={<Navigation />}>
                  {isEnterpriseDashboardEnabled && (
                    <Route path={ROUTE_CONSTANTS.DASHBOARD} element={<Dashboard />} />
                  )}
                  {!isEmployer && (
                    <Route path={ROUTE_CONSTANTS.CORPORATIONS} element={<CorporationList />} />
                  )}
                  {isAdmin && (
                    <Route
                      path={ROUTE_CONSTANTS.CORPORATION_CREATE}
                      element={<CreateCorporation />}
                    />
                  )}

                  {isAdmin && (
                    <Route path={ROUTE_CONSTANTS.SKILLS_AND_CERTS} element={<SkillsAndCerts />} />
                  )}
                  {isAdmin && isAdminMetricsPageEnabled && (
                    <Route path={ROUTE_CONSTANTS.REPORTING} element={<Reporting />} />
                  )}
                  {!isEmployer && <Route path={ROUTE_CONSTANTS.USERS} element={<UserList />} />}

                  <Route path={ROUTE_CONSTANTS.SETTINGS} element={<Settings />} />
                  <Route path={ROUTE_CONSTANTS.JOB_ORDERS} element={<JobOrders />} />
                  <Route path={`${ROUTE_CONSTANTS.JOB_ORDERS}/:id`} element={<JobOrderDetail />} />
                  {(isAdmin || isRecruiter) && (
                    <>
                      <Route path={ROUTE_CONSTANTS.USERS_V2} element={<UsersList />} />
                      <Route path={`${ROUTE_CONSTANTS.USERS_V2}/:id`} element={<UserDetail />} />
                    </>
                  )}
                  <Route path={ROUTE_CONSTANTS.LONG_TERM_JOBS} element={<JobOrders />} />
                  <Route path={ROUTE_CONSTANTS.JOB_ORDER_CREATE} element={<JobOrdersCreate />} />
                  <Route
                    path={ROUTE_CONSTANTS.POSITION_TEMPLATES}
                    element={<PositionTemplateList />}
                  />
                  {timesheetToggle && (
                    <Route
                      path={`${ROUTE_CONSTANTS.TIMEKEEPING}/:${searchParamOptions.WEEK_START}?/:${searchParamOptions.CANDIDATE}?/:${searchParamOptions.PLACEMENT}?/:${searchParamOptions.SELECTED_DATE}?`}
                      element={<TimekeepingGridView />}
                    />
                  )}
                </Route>
              )}
              {isEmployee && <Route path={ROUTE_CONSTANTS.DASHBOARD} element={<MobileDirect />} />}
              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </HistoryRouter>
      </IntlProvider>
    );
  }
  return null;
};

AppRouter.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(AppRouter);
