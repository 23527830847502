import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const DeactivatedAdminIcon = () => (
  <SvgIcon>
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.60608 7.74025L12 5L18.3939 7.74025C18.7616 7.89783 19 8.25937 19 8.6594V15.5181C19 17.6712 17.8463 19.6593 15.9768 20.7275L12 23L8.02317 20.7275C6.15372 19.6593 5 17.6712 5 15.5181V8.6594C5 8.25937 5.2384 7.89783 5.60608 7.74025Z"
        stroke="#B23E3E"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.42L10.42 10L12.54 12.13L14.66 10L16.08 11.42L13.95 13.54L16.08 15.66L14.66 17.08L12.54 14.95L10.42 17.08L9 15.66L11.13 13.54L9 11.42Z"
        fill="#B23E3E"
      />
    </svg>
  </SvgIcon>
);

export default DeactivatedAdminIcon;
