import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SuspendUserIcon = () => (
  <SvgIcon>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1564_7877)">
        <path
          d="M10 6.5C8.93913 6.5 7.92172 6.92143 7.17157 7.67157C6.42143 8.42172 6 9.43913 6 10.5C6 11.5609 6.42143 12.5783 7.17157 13.3284C7.92172 14.0786 8.93913 14.5 10 14.5C11.0609 14.5 12.0783 14.0786 12.8284 13.3284C13.5786 12.5783 14 11.5609 14 10.5C14 9.43913 13.5786 8.42172 12.8284 7.67157C12.0783 6.92143 11.0609 6.5 10 6.5ZM10 8.5C10.5304 8.5 11.0391 8.71071 11.4142 9.08579C11.7893 9.46086 12 9.96957 12 10.5C12 11.0304 11.7893 11.5391 11.4142 11.9142C11.0391 12.2893 10.5304 12.5 10 12.5C9.46957 12.5 8.96086 12.2893 8.58579 11.9142C8.21071 11.5391 8 11.0304 8 10.5C8 9.96957 8.21071 9.46086 8.58579 9.08579C8.96086 8.71071 9.46957 8.5 10 8.5ZM10 15.5C7.33 15.5 2 16.83 2 19.5V22.5H11.5C11.2483 21.894 11.0899 21.2534 11.03 20.6H3.9V19.5C3.9 18.86 7.03 17.4 10 17.4C10.5 17.4 11 17.45 11.5 17.53C11.7566 16.8985 12.1109 16.3114 12.55 15.79C11.61 15.6 10.71 15.5 10 15.5ZM17.5 15.5C15 15.5 13 17.5 13 20C13 22.5 15 24.5 17.5 24.5C20 24.5 22 22.5 22 20C22 17.5 20 15.5 17.5 15.5ZM17.5 17C19.16 17 20.5 18.34 20.5 20C20.5 20.56 20.35 21.08 20.08 21.5L16 17.42C16.42 17.15 16.94 17 17.5 17ZM14.92 18.5L19 22.58C18.58 22.85 18.06 23 17.5 23C15.84 23 14.5 21.66 14.5 20C14.5 19.44 14.65 18.92 14.92 18.5Z"
          fill="#B23E3E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1564_7877">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default SuspendUserIcon;
