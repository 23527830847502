import { map } from 'lodash/fp';
import get from 'lodash/fp/get';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import { createSlice } from '@reduxjs/toolkit';

import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../utils';

const initialState = {
  users: [],
  totalRowCount: 0,
  isUsersLoading: false,
  isUpdateProcessing: false,
  hasUpdated: null,
};

export const usersListSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    getUsers: (state, action) => {
      if (action?.payload?.page === 0) {
        state.users = [];
      }
      state.isUsersLoading = true;
    },
    getUsersProcessed: (state, action) => {
      state.isUsersLoading = false;
      const { count, documents } = action.payload.data;
      const mappedUsers = map((user) => {
        const { _id, id, name, role, metadata, recruiter, candidate, email, account, status } =
          user;
        const formatLastLogin = (timestamp) => {
          if (!timestamp) return null;
          const date = epochToDateInReadableFormat(timestamp);
          const time = epochToTimeInReadableFormat(timestamp);
          return `${date} at ${time}`;
        };

        return {
          account,
          id: id || _id,
          name,
          role,
          externalId: get(['bullhorn', 'id'], metadata),
          email,
          candidate,
          recruiter,
          phoneNumber: get(['candidate', 'phoneNumber'], user),
          status,
          location: 'Location placeholder',
          rating: user?.rating,
          lastLoginDate: formatLastLogin(user?.lastLoginDate),
          corporationName:
            user?.role === UserRole.EMPLOYER ? user?.employer?.corporation?.name : null,
        };
      }, documents);
      state.users.push(...mappedUsers);
      state.totalRowCount = count;
    },

    getUsersError: (state) => {
      state.isUsersLoading = false;
      state.totalRowCount = 0;
      state.users = [];
    },
    getUserDetail: (state, action) => {
      if (action?.payload?.page === 0) {
        state.users = [];
      }
      state.isUserDetailLoading = true;
    },
    getUserDetailProcessed: (state, action) => {
      state.isUserDetailLoading = false;
      const { documents } = action.payload.data;
      const mappedUsers = map((user) => {
        const { _id, id, name, role, metadata, recruiter, candidate, email, account, status } =
          user;

        const formatLastLogin = (timestamp) => {
          if (!timestamp) return null;
          const date = epochToDateInReadableFormat(timestamp);
          const time = epochToTimeInReadableFormat(timestamp);
          return `${date} at ${time}`;
        };
        return {
          account,
          id: id || _id,
          name,
          role,
          externalId: get(['bullhorn', 'id'], metadata),
          email,
          candidate,
          recruiter,
          phoneNumber: get(['candidate', 'phoneNumber'], user),
          status,
          location: 'Location placeholder',
          rating: user?.rating,
          lastLoginDate: formatLastLogin(user?.lastLoginDate),
        };
      }, documents);
      state.userDetail = mappedUsers;
    },

    getUserDetailError: (state) => {
      state.isUserDetailLoading = false;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    updateUser: (state) => {
      state.isUpdateProcessing = true;
    },
    updateUserProcessed: (state, action) => {
      state.hasUpdated = action.payload.data.id;
      state.isUpdateProcessing = false;
    },
    updateUserError: (state) => {
      state.isUpdateProcessing = false;
    },
  },
});

export const usersListReducer = usersListSlice.reducer;

export const {
  getUsers,
  getUsersError,
  getUserDetail,
  getUserDetailProcessed,
  getUserDetailError,
  getUsersProcessed,
  updateUser,
  updateUserProcessed,
  updateUserError,
  setUserDetail,
  updateUserCards,
} = usersListSlice.actions;
