import { get, getOr } from 'lodash/fp';

const launchDarklyToggles = (flags, toggleName) =>
  // FIXME: HB-897 remove inverted values on the disables
  get(toggleName, {
    // Enables or disables the admin's ability to view the metrics page
    isAdminMetricsPageEnabled: getOr(true, 'adminMetricsPageIsEnabled', flags),

    isEnterpriseDashboardEnabled: getOr(false, 'isEnterpriseDashboardEnabled', flags),

    // Enables or disables dashboard navigation on sidebar menu
    // toggles between true or false
    // By default, dashboard select will be disabled, darkly return value: false.
    isSideBarDashboardButtonEnabled: getOr(false, 'dashboardToggle', flags),

    // Enables or disables the creation of long term jobs
    // toggles between true or false
    // By default, the creation of long term jobs will be enabled, darkly return value: true.
    isLongTermJobCreationEnabled: getOr(true, 'longTermJobCreate', flags),

    // Enables or disables employer select option on register page
    // toggles between true or false
    // By default, employer registration will be enabled, darkly return value: true.
    isRegisterEmployerEnabledOnSignUp: getOr(true, 'registerEmployer', flags),

    // Enables or disables employee select option on register page
    // toggles between true or false
    // By default, employee registration will be disabled, darkly return value: false.
    isRegisterEmployeeEnabledOnSignUp: getOr(false, 'registerEmployee', flags),

    // Enables or disables timesheets
    // toggles between true or false
    // By default, timesheets will be disabled, darkly return value: false.
    isTimesheetEnabled: getOr(false, 'timesheetToggle', flags),

    // toggles between true or false
    // By default, the maintenance page will be disabled, darkly return value: (false).
    isMaintenanceModeEnabled: getOr(false, 'maintenanceMode', flags),

    // toggles between true or false
    isRecruiterEmployeeCertificationBlocked: getOr(
      false,
      'recruitersForbiddenToEditCertsOnCandidate',
      flags
    ),

    // toggles between true false for candidate creation via workflow.
    // by default, candidate creation via workflow is disabled.
    isCandidateCreationViaWorkflowEnabled: getOr(
      false,
      'candidateCreationViaWorkflowToggle',
      flags
    ),

    // toggles between true false for bullhorn decouple
    // by default, value is true.
    isWaeBhDecoupled: getOr(false, 'isWaeBhDecoupled', flags),

    // toggles between true false for long term job detail job maintenance mode.
    // by default, long term job maintenance mode is disabled.
    isLongTermJobDetailInMaintenanceMode: getOr(false, 'longTermJobDetailsMaintenanceMode', flags),

    // toggles between true false for Gps Enforcement Page on Position Template Create Drawer.
    // by default, GPS enforcement is disabled.
    isGPSEnforcementPageInPTCreateDrawerOn: getOr(
      false,
      'ptCreateDrawerGpsEnforcementPageToggle',
      flags
    ),

    // toggles between true false for disabling the Shift Group Toggle on Job Create Drawer.
    // by default, Shift Group Toggle is disabled.
    isShiftGroupToggleInJobCreateDrawerEnabled: getOr(
      false,
      'jobCreateDrawerShiftGroupToggle',
      flags
    ),

    // toggles between true false for for enabling .
    // by default, Shift Group Toggle is disabled.
    isWorkableGroupsV2Active: getOr(false, 'isWorkableGroupsV2Active', flags),

    // toggles between true false for enabling the allow applications field on PTs
    // by default, job applications are not allowed
    isJobApplicationSystemEnabled: getOr(false, 'isJobApplicationSystemEnabled', flags),

    // toggles whether a recruiter can add and remove candidates from job placements.
    isRecruiterForbiddenToCreateCancelPlacements: getOr(
      false,
      'recruitersForbiddenToCreateAndCancelPlacements',
      flags
    ),

    // toggles between true false for disabling the Skill Section on Candidate User Detail.
    // by default, Shift Group Toggle is disabled.

    isNotificationCenterEnabled: getOr(false, 'notificationCenterIsEnabled', flags), // toggles between true false for disabling the Shift Group Toggle on Job Create Drawer.
    // by default,Notification Center is disabled.

    isSkillSectionForCandidateUserDetailEnabled: getOr(false, 'skillSection', flags),
    flags,

    isPayrollV2Enabled: getOr(false, 'isPayrollV2Enabled', flags),
    isHackathonTimesheetsViewEnabled: getOr(false, 'isHackathonTimesheetsViewEnabled', flags),
  });
export default launchDarklyToggles;
