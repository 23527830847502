import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import FavoriteHeartIcon from '../../../../../assets/icons/FavoriteHeartIcon';
import { pendingJobStatuses, statusActionAText, statusActionBText } from '../../constants';

import PendingStatusMenuButton from './PendingStatusMenuButton';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: '1px solid #E0E0E0',
  },
  propText: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#7A7A7A',
  },
});

const PendingEmployeeRosterCard = ({
  placement,
  onRevokeOrAcceptInvite,
  onResendOrRejectInvite,
  status,
}) => {
  const classes = useStyles();

  const statusMessage =
    (pendingJobStatuses[status] && LanguageConverter(pendingJobStatuses[status])) || '';

  const reliabilityScore = placement?.reliabilityScore;

  const pendingMenuActions = [
    {
      text: (statusActionAText[status] && LanguageConverter(statusActionAText[status])) || '',
      onClick: onRevokeOrAcceptInvite,
    },
    {
      text: (statusActionBText[status] && LanguageConverter(statusActionBText[status])) || '',
      onClick: onResendOrRejectInvite,
    },
  ];

  return (
    <Box className={classes.container} data-testid="pendingRosterCard">
      <Box display="flex" alignItems="center" flex={7}>
        <Avatar src={placement?.employeeProfilePicture} alt={placement?.employeeName} />
        <Typography style={{ marginLeft: '10px', fontSize: '24px' }}>
          {placement?.employeeName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '24px', padding: '0 10px', flex: 3.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.favorite')}
          </Typography>
          <FavoriteHeartIcon isFavorite={placement?.isFavorite} width={36} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.reliability')}
          </Typography>
          <Typography sx={{ display: 'flex', fontSize: '18px' }}>
            {typeof reliabilityScore === 'number' && !Number.isNaN(reliabilityScore)
              ? `${Math.round(reliabilityScore)}%`
              : '--'}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.status')}
          </Typography>
          <PendingStatusMenuButton status={statusMessage} actions={pendingMenuActions} />
        </Box>
      </Box>
    </Box>
  );
};

PendingEmployeeRosterCard.propTypes = {
  placement: PropTypes.shape({
    id: PropTypes.string,
    employeeName: PropTypes.string,
    employeeProfilePicture: PropTypes.string,
    isFavorite: PropTypes.bool,
    reliabilityScore: PropTypes.number,
    status: PropTypes.string,
  }),
  onRevokeOrAcceptInvite: PropTypes.func,
  onResendOrRejectInvite: PropTypes.func,
  status: PropTypes.string,
};

export default PendingEmployeeRosterCard;
