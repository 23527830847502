import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const DeactivatedUserIcon = () => (
  <SvgIcon>
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4.88L1.42 3.46L3.54 5.59L5.66 3.46L7.08 4.88L4.95 7L7.08 9.12L5.66 10.54L3.54 8.41L1.42 10.54L0 9.12L2.13 7L0 4.88ZM13.54 0C14.6009 0 15.6183 0.421427 16.3684 1.17157C17.1186 1.92172 17.54 2.93913 17.54 4C17.54 5.06087 17.1186 6.07828 16.3684 6.82843C15.6183 7.57857 14.6009 8 13.54 8C12.4791 8 11.4617 7.57857 10.7116 6.82843C9.96143 6.07828 9.54 5.06087 9.54 4C9.54 2.93913 9.96143 1.92172 10.7116 1.17157C11.4617 0.421427 12.4791 0 13.54 0ZM13.54 1.9C12.983 1.9 12.4489 2.12125 12.0551 2.51508C11.6612 2.9089 11.44 3.44305 11.44 4C11.44 4.55695 11.6612 5.0911 12.0551 5.48492C12.4489 5.87875 12.983 6.1 13.54 6.1C14.7 6.1 15.64 5.16 15.64 4C15.64 2.84 14.7 1.9 13.54 1.9ZM13.54 9C16.21 9 21.54 10.33 21.54 13V16H5.54V13C5.54 10.33 10.87 9 13.54 9ZM13.54 10.9C10.54 10.9 7.44 12.36 7.44 13V14.1H19.64V13C19.64 12.36 16.51 10.9 13.54 10.9Z"
        fill="#B23E3E"
      />
    </svg>
  </SvgIcon>
);
export default DeactivatedUserIcon;
